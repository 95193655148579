.feed {
  padding-left: 0px;
  padding-right: 0px;

  .sale-wapper {
    padding: 10px;
    background: white;
    text-align: center;

    .sale-title {
      font-size: var(--font-size-3xl);
    }

    .btn-econdos-primary {
      background-color: var(--primary);
      color: white;

      &:hover {
        background-color: #c59811;
      }
    }

    .fa-diamond {
      color: var(--primary);
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .fa-phone {
      color: $econdos-blue;
    }
  }

  .content-wrapper {
    padding-left: 0px;
    padding-right: 0px;
  }

  .notification-badge {
    background-color: $danger;
  }

  .border-closed {
    border-left: solid 3px var(--green-500);
  }

  .border-opened {
    border-left: solid 3px var(--blue-500);
  }

  .border-canceled {
    border-left: solid 3px var(--red-500);
  }

  .card-title-xs {
    font-size: var(--font-size-xl);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .action-footer {
    border-top: solid 1px #f3f3f3;
    padding: 5px;
  }

  .infinite-card {
    background: #fff;
    margin-bottom: 20px;
  }

  .go-to-top-button-wrapper {
    position: absolute;
    right: 32px;

    .go-to-top-button {
      width: 60px;
      height: 60px;
      font-size: var(--font-size-4xl);
      position: fixed;
      bottom: 50px;
      z-index: 3;
      display: block;

      &:focus {
        outline: none;
      }
    }
  }

  .filter-occurrence {
    .panel-body {
      padding: 0px 5px 5px 5px;
    }

    .filter-header {
      border-bottom: 1px solid #dddfe2;
      padding: 5px;
    }

    .filter-body {
      padding: 10px 10px 5px 10px;
    }

    .filter-footer {
      border-top: 1px solid #dddfe2;
      padding: 5px;
    }

    .filter-text {
      display: inline;
      .form-control {
        display: inline-block;
        width: calc(100% - 150px);
        min-width: 195px;
      }
    }

    .label-wrapper {
      display: inline-block;
      width: 85px;
      vertical-align: top;
      padding-top: 8px;
    }

    .filter-range-picker {
      margin-top: 10px;

      .filter-residence {
        display: block;
        .datepicker-wrapper {
          display: inline-block;
          width: 210px;
        }
        .loading-feedback {
          display: inline-block;
          width: 180px;
          vertical-align: top;
          padding-top: 6px;
          margin-bottom: 6px;
        }
      }

      .datepicker-wrapper {
        display: inline-block;
        width: 210px;
      }
    }
  }

  @media (max-width: 768px) {
    .go-to-top-button-wrapper {
      right: 45px;
      .go-to-top-button {
        width: 55px;
        height: 55px;
      }
    }
  }
}
