.clear{
  border-color: transparent;
  background-color: transparent;
  box-shadow: none;
  opacity: 1;
  outline: 0;
}

.clear:active{
  background-color: rgba(158, 158, 158, 0.2);
  outline: 0;
}

.btn-econdos-primary {
  background-color: var(--primary);
  border-color: var(--primary);
  color: var(--on-primary) !important;
}

.btn-econdos-green {
  background-color: $econdos-green;
  border-color: #2D632D;
  color: white!important;
}

.btn-econdos-blue {
  background-color: $econdos-blue;
  border-color: #2b5eb9;
  color: white!important;
}
