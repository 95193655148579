:host {
  ::ng-deep {
    app-table {
      main {
        height: calc(100vh - 290px);
        min-height: 400px;
      }

      &.with-filters main {
        height: calc(100vh - 300px);
      }
    }
  }
}
