.apartment-picker-modal {

  .residence-cell {
    display: inline-block;
    padding: 4px;
    //border: solid 1px #555;
    width: 35px;
    text-align: center;
    font-size: var(--font-size-sm);
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover{
      outline: 2px auto #a5c7fe;
      outline-offset: -2px;
    }
  }

  .button-wrapper{
    padding: 10px 10px;
    display: inline-block;

    .block-button{
      min-width: 50px;
      height: 50px;
    }
  }

  .checked{
    background-color: var(--primary);
    border-radius: 50%;
    font-weight: bold;
  }

  .disabled{
    opacity: 0.3;
  }

  .econdos-primary{
    background: white;
    color: var(--primary);
    border-color: var(--primary);
    transition: all .2s ease-in-out;
    &:hover{
      transform: scale(1.3);
      font-weight: bold;
    }
    &.active{
      font-weight: bold;
      background: var(--primary);
      color: #fff;
    }

  }

}
