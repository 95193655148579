.comment-modal {

  .show-options-button {
    color: $facebook-gray!important;
  }

  .modal-body {
    height: 70%;
    max-height: 420px;
    overflow-y: auto;
    padding-top: 5px;

    .row {
      min-height: 45px;

      &:hover {
        .options-button {
          color: $facebook-gray!important;
        }
      }
    }

    .comment-wrapper {

      display: inline-block;
      font-size: var(--font-size-sm);
      padding-left: 4px;

      .comment-name {
        font-weight: bold;
      }
      .comment-message {
        color: #1d2129;
      }
    }

    .comment-image {
      width: 30px;
      height: 30px;
      vertical-align: bottom;
    }

    .comment-padding {
      padding: 4px 0px;
    }

    .comment-write {
      padding-top: 4px;
    }

    .options-button {
      position: absolute;
      color: transparent;
      right: 5px;
      top: -5px;

      .active {
        color: $facebook-gray!important;
      }

      .dropdown-menu {
        cursor: pointer;
      }
    }

    .input-comment-wrapper {
      padding-left: 5px;
      width: 100%;

      input {
        width: 100%;
      }

      .post-comment-icon {
        width: 35px;
      }
    }

    .cancel-comment-editing-message {
      font-size: 0.5625rem;
    }
  }

}
