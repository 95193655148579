/* ==========  Select Field Variables ========== */
$color-black: "0,0,0";
$select-background-color: transparent;
$select-border-color: unquote("rgba(#{$color-black}, 0.12)") !default;
$select-font-size: var(--font-size-lg);
$select-color: unquote("rgba(#{$color-black}, 0.7)") !default;
$select-padding: 4px;

/* ==========  Select Field ========== */

/* Style Select Field */
select {
  font-family: inherit;
  background-color: transparent;
  //width: 100%;
  padding: $select-padding 0;
  font-size: $select-font-size;
  color: $select-color;
  border: none;
  border-bottom: 1px solid $select-border-color;
}

/* Remove focus */
select:focus {
  outline: none}

/* Hide label */
//.mdl-selectfield label {display: none;}
/* Use custom arrow */
.mdl-selectfield select {appearance: none}
.mdl-selectfield {
  font-family: Roboto,system-ui,-apple-system,"Segoe UI", Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  position: relative;
  &:after {
    position: absolute;
    top: 0.75em;
    right: 0.5em;
    /* Styling the down arrow */
    width: 0;
    height: 0;
    padding: 0;
    content: '';
    border-left: .25em solid transparent;
    border-right: .25em solid transparent;
    border-top: .375em solid $select-border-color;
    pointer-events: none;
  }
}
