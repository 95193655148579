.files-scroll {
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: visible;

  a {
    cursor: pointer;
  }

  li {
    display: inline-block;
    margin: 5px;
    vertical-align: middle;
    vertical-align: top;
    position: relative;

    &.loader-container {
      width: 80px;
      height: 80px;
      .mini-loader {
        width: 60px;
        height: 60px;
        margin: 0 auto;
        margin-top: 5px;
      }

    }

    upload-button {
      button {
        border: 2px dashed #dddfe2;
        border-radius: 2px;
        box-sizing: border-box;
        width: 80px;
        height: 80px;
        span {
          white-space: normal;
        }
        i {
          color: #90949C;
        }
      }
    }

    img {
      width: auto;
      max-height: 80px;
    }

    .play-icon {
      color: var(--primary);
      position: absolute;
      top: calc(50% - 28px);
      left: calc(50% - 24px);
    }

    .remove-image-icon {
      position: absolute;
      margin-top: 4px;
      margin-left: 5px;
      font-size: var(--font-size-3xl);
      color: #F99F1B;
      cursor: pointer;

      &:hover {
        opacity: 0.7;
      }
    }

  }

}
