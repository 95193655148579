.signup {
  min-height: 100vh;
  background: var(--neutral-100);
  overflow: auto;
  padding-bottom: 20px;

  .tabset-wrapper {

    .form {
      padding: 15px;
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      border-top-right-radius: 10px;
    }

  }

  .logo {
    padding-top: 20px;
    text-align: center;
    margin-bottom: 15px;

    img {
      max-height: 15vh;
    }
  }
}
