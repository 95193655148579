modal-qrcode {

  .modal-body{
    min-height: 300px;
    min-width: 400px;
  }

  .small-modal{
    width: 400px;
  }
}
