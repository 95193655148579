subscription-page {
  .strikediag {
    background: linear-gradient(to left top, transparent 47.75%, currentColor 49.5%, currentColor 50.5%, transparent 52.25%);
    opacity: 0.7;
  }
  .discount-price {
    font-size: var(--font-size-lg);
    font-weight: bold;
    vertical-align: bottom;
  }
}
