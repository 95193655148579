.vehicle-pick-modal {
  .vehicles-pick-modal-body {
    height: 420px;
    overflow: auto;

    .vehicles-list {
      .vehicle-item {
        margin-bottom: 10px;
        cursor: pointer;

        width: calc(50% - 10px);
        float: left;
        margin-right: 5px;
        margin-left: 5px;

        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        transition: 0.3s;
        border: solid 1px transparent;

        &:hover {
          box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
        }

        &.selected-card {
          border-color: var(--primary);
          border-style: solid;
          border-width: thin;
        }

        .vehicle-image {
          display: inline-block;

          img {
            height: 120px;
            width: 120px;
          }
        }

        .vehicle-info {
          padding-right: 15px;
          display: inline-block;
          vertical-align: middle;
        }
      }
    }
  }
}
