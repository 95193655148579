condo-tabs {

  @media(max-width: 980px) {
    .big-tab-selection {
      display: none;
    }

    .small-tab-selection {
      display: initial;
    }
  }

  @media(min-width: 980px) {
    .big-tab-selection {
      display: initial;
    }

    .small-tab-selection {
      display: none;
    }
  }

  .tab-button {
    width: 100%;
  }

  tabset {
    .nav-tabs > .nav-item > a {
      flex-direction: column;
      gap: 0.25rem;

      .fa {
        font-size: var(--font-size-2xl);
      }

      @media screen and (min-width: 1190px) {
        flex-direction: row;
        gap: 0.5rem;

        .fa {
          font-size: var(--font-size-lg);
        }
      }
    }
  }
}
