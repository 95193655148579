.condo-list {
  .error-message {
    color: #f82922;
    font-size: smaller;
  }

  a {
    cursor: pointer;
  }

  .opacity {
    opacity: 0.5;
  }

  .change-imagem-button {
    position: absolute;
    width: 120px;
    height: 120px;

    upload-button button {
      width: 100%;
      height: 100%;
      background: none;
      border: none;
      border-radius: 50%;
    }
  }

  .condo-logo {
    width: 120px;
    height: 120px;

    .logo {
      width: 100%;
      height: 100%;
      object-fit: contain;
      border-radius: 50%;
    }
  }

  .dropdown-condo {
    width: 100%;

    button {
      width: 100%;
      text-align: left;
      border-top-left-radius: 5px !important;
      border-bottom-left-radius: 5px !important;
    }

    .button-enabled {
      background: transparent;
      border-color: #828282;
    }

    .button-disabled {
      color: #555 !important;
      background-color: #eeeeee !important;
      border-color: #cccccc !important;
      opacity: 1;
    }

    .dropdown-list {
      max-height: 150px;
      overflow: auto;
      z-index: 2;
      cursor: pointer;
      position: absolute;
      min-width: 100%;

      .list-group-item:hover {
        background-color: #ECECEC;
        border-color: inherit;
        color: inherit;
      }
    }
  }

  .dropdown-toggling {
    padding: 0.5rem 0;
    border-radius: 4px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .action-dropdown {
      color: transparent;
    }

    &:hover {
      background: var(--neutral-100);

      .action-dropdown {
        color: initial;
      }
    }
  }

  .active-button {
    border-radius: 4px;
    color: var(--blue-500);
    transition: all 0.2s;

    &:hover {
      background: var(--neutral-100);
    }

    &:active {
      transform: scale(0.9);
    }
  }

  .contributor-wrapper {
    display: flex;
    align-items: center;

    .contributor-img {
      width: 32px;
      height: 32px;
      object-fit: contain;
      border-radius: 50%;
    }
  }

  .condo-details {
    .condo-details-card {
      width: 100%;
      margin: 0 auto;

      display: flex;
      flex-direction: column;
      gap: 1rem;

      .condo-details-card-title {
        margin: 0;
        height: 2.1875rem;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;

        h4 {
          margin: 0;
          font-weight: bold;
        }
      }
    }
  }
}
