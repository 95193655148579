// From sm screens up
@mixin screen-sm {
  @media (min-width: 768px) { @content; }
}
// From md screens up
@mixin screen-md {
  @media (min-width: 992px) { @content; }
}
// From lg screens up
@mixin screen-lg {
  @media (min-width: 1200px) { @content; }
}
// From xl screens up
@mixin screen-xl {
  @media (min-width: 1400px) { @content; }
}
