.bs-datepicker-head {
  background-color: var(--primary) !important;
}

.bs-datepicker-body table td span.selected,
.bs-datepicker-body table td.selected span,
.bs-datepicker-body table td span[class*="select-"]:after,
.bs-datepicker-body table td[class*="select-"] span:after {
  background-color: var(--primary) !important;
}

.bs-datepicker-body table td.week span {
  color: var(--primary) !important;
}
