.dashboard-section {
  background-color: #FFFFFF;
  padding: 0.75rem;
  border-radius: 0.25rem;

  .dashboard-card-list {
    list-style: none;
    padding: 0;

    display: flex;
    flex-direction: column;
    gap: 0.75rem;

    .dashboard-card-list__item {
      background-color: #FFFFFF;
      padding: 0.75rem;
      border: 1px solid var(--neutral-200);
      border-radius: 0.25rem;
      transition: all 0.2s;
      cursor: pointer;
      position: relative;

      &:hover {
        box-shadow: var(--shadow-md);
      }

      .dashboard-card-list__item-arrow-right {
        font-size: var(--font-size-sm);
        color: var(--neutral-400);
        opacity: 0;
        transition: all 0.2s;

        background: transparent;
        padding: 0.75rem 0.75rem 0.75rem 2rem;

        position: absolute;
        top: 0;
        right: 0.25rem;
      }

      .dashboard-card-list__item-title {
        h2 {
          font-size: var(--font-size-base);
          font-weight: 500;
          margin: 0;
        }

        small {
          font-size: var(--font-size-xs);
          color: var(--neutral-400);
        }
      }

      .dashboard-card-list__item-createdby {
        img {
          width: 1.25rem;
          aspect-ratio: 1 / 1;
          border-radius: 50%;
          object-fit: contain;
          border: 1px solid var(--neutral-200);
        }

        span {
          font-size: var(--font-size-xs);
          color: var(--neutral-600);
        }
      }

      .dashboard-card-list__item-statistics {
        font-size: var(--font-size-xs);
        color: var(--neutral-500);
      }

      &:hover {
        .dashboard-card-list__item-arrow-right {
          opacity: 1;
          right: 0;
          background: linear-gradient(to left, #FFFFFF 60%, transparent 100%);
        }
      }
    }
  }

  .dashboard-tabs {
    width: 100%;
    margin-bottom: 0.75rem;

    display: flex;
    gap: 0.75rem;

    & > button {
      padding: 0.75rem;
      background: #FFFFFF;
      border: 2px solid #FFFFFF;
      border-top-left-radius: 0.25rem;
      border-top-right-radius: 0.25rem;
      font-size: var(--font-size-sm);
      font-weight: normal;
      color: var(--neutral-400);
      transition: all 0.2s;

      &:hover {
        color: var(--neutral-500);
        border-bottom-color: var(--neutral-500);
      }

      &.active {
        font-weight: 500;
        color: var(--primary-500);
        border-bottom-color: var(--primary-500);

        &:hover {
          background-color: var(--primary-100);
          border-bottom-color: var(--primary-500);
        }
      }
    }
  }

  .summary {
    background-color: #FFFFFF;
    padding: 0.75rem;
    border: 1px solid var(--neutral-200);
    border-radius: 0.25rem;

    display: flex;

    .summary__item {
      flex: 1;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.25rem;

      & + .summary__item {
        border-left: 1px solid var(--neutral-200);
      }

      strong {
        font-size: var(--font-size-3xl);
        font-weight: 900;
      }

      span {
        font-size: var(--font-size-xs);
        font-weight: 500;
        color: var(--neutral-500);
      }
    }
  }

  .dashboard-table {
    width: 100%;
    max-width: 100%;
    border-collapse: separate;
    border: 1px solid var(--neutral-200);
    border-radius: 0.25rem;

    td, th {
      &:first-child {
        padding-left: 0.75rem;
      }

      &:last-child {
        padding-left: 0.75rem;
      }
    }

    thead {
      color: var(--neutral-400);
      font-size: var(--font-size-sm);
      font-weight: 500;
      text-transform: uppercase;

      th {
        padding: 0.75rem 0;
      }
    }

    tbody {
      font-size: var(--font-size-base);
      color: var(--neutral-600);

      td {
        padding: 0.75rem 0;
        border-top: 1px solid var(--neutral-200);
      }

      tr:hover td {
        cursor: pointer;
        background-color: rgb(0 0 0 / 0.05);
      }
    }
  }

  .statistic-indicator {
    background-color: #FFFFFF;
    padding: 1rem;
    border-radius: 0.25rem;
    overflow: hidden;
    cursor: pointer;
    transition: all 0.2s;
    position: relative;

    display: flex;
    align-items: flex-start;
    gap: 1.5rem;

    &:hover {
      box-shadow: var(--shadow-md);
    }

    .statistic-indicator__icon {
      min-width: 3.25rem;
      min-height: 3.25rem;
      max-width: 3.25rem;
      max-height: 3.25rem;
      width: 100%;
      height: 100%;
      background: var(--neutral-200);
      border-radius: 50%;

      font-size: var(--font-size-3xl);
      color: var(--neutral-500);
      line-height: 1;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .statistic-indicator__info-wrapper {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;

      strong {
        font-size: var(--font-size-4xl);
        font-weight: 900;
        color: var(--neutral-600);
        line-height: 1;
      }

      small {
        font-size: var(--font-size-xs);
        color: var(--neutral-400);
        text-transform: uppercase;
      }
    }

    .statistic-indicator__stripe {
      height: 6px;
      background: linear-gradient(to left, var(--neutral-300), var(--neutral-500));

      position: absolute;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }
}

.label {
  line-height: inherit;
}
