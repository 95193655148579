.condos-autocomplete {
  box-sizing: border-box;
  .input-area {
    input{
      border: none;
      display: block;
      color: $facebook-gray;
      padding: 5px 0 0 0;
      width: 100%;
      font-size: var(--font-size-lg);
      &:focus {
        outline: none;
      }
    }
  }

  .dropdown-list {
    max-height: 150px;
    overflow: auto;
    z-index: 2;
    cursor: pointer;
    box-shadow: 3px 3px 5px #888888;
    position: absolute;

    .list-group-item.active, .list-group-item.active:focus, .list-group-item.active:hover {
      background-color: var(--primary);
      border-color: var(--primary);
    }

    .list-group-item:hover {
      background-color: var(--primary);
      border-color: var(--primary);
      color: white;
    }
  }

  .input-group-addon {
    background-color: transparent;
    border: none;
  }
}
