.navbar-econdos {
  background-color: var(--navbar-background);
  border-color: var(--navbar-background);
  color: var(--navbar-text);

  .navbar-brand {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }

  li {
    padding: 0.375rem;

    a {
      border-radius: 0.5rem;
      color: var(--navbar-text);

      &:not(.role-label):hover {
        background: var(--navbar-hover-background);
        border-color: var(--navbar-hover-background);
        color: var(--navbar-hover-text);
      }
    }
  }

  .navbar-nav > .active > a {
    background: var(--navbar-hover-background);
    color: var(--navbar-hover-text);

    &:hover {
      background: var(--navbar-hover-background);
      color: var(--navbar-hover-text);
    }

    &:focus {
      background: var(--navbar-hover-background);
      color: var(--navbar-hover-text);
    }
  }

}




