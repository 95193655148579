.d-sm-none {
  @media (max-width: 991px) {
    display: none;
  }
}

.condo-residents {

  $height: calc(100vh - 256px);
  $table-min-height: 400px;
  $panel-height: calc(100vh - 303px);

  .ngx-datatable.scroll-vertical {
    height: $height;
    min-height: $table-min-height;
  }

  .datatable-body-cell {
    cursor: pointer;

    .requests-badge {
      background-color: $danger;
      position: absolute;
      top: 0px;
      right: -3px;
      padding: 0;
      border-radius: 50%;
      font-size: var(--font-size-xxs);
      min-width: 10px;
    }
  }

  .datatable-body-cell-label {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .role-label {
    font-size: var(--font-size-sm);
  }

  @media (min-width: 978px) {
    .details-panel {
      min-height: $table-min-height - 63px;
    }
  }

  .reject-requester-btn {
    color: #a94442;

    &:hover {
      color: $danger;
    }
  }

  .accept-requester-btn {
    color: #3c763d;
    padding-right: 0px;

    &:hover {
      color: $secondary;
    }
  }

  .accept-requester-btn, .reject-requester-btn {
    padding-top: 0px;
    padding-bottom: 0px;

    i {
      font-size: var(--font-size-3xl);
    }
  }

  .remove-residence-btn {
    padding: 0px;

    i {
      font-size: var(--font-size-3xl);
    }
  }

  .list-group-item-text {
    cursor: pointer;
    color: #333;
  }

  .filter-chip {
    .chip{
      height: auto;
      line-height: 1.42857143;
    }
  }

  .chip {
    display: inline-block;
    padding: 0 7px 0 20px;
    height: 40px;
    font-size: var(--font-size-lg);
    line-height: 40px;
    border-radius: 20px;
    background-color: #f1f1f1;
    margin-bottom: 7px;
    max-width: 350px;

    img {
      float: left;
      margin: 0 10px 0 -20px;
      height: 40px;
      width: 40px;
      border-radius: 50%;
    }

    button {
      padding-right: 0;
      font-size: var(--font-size-2xl);
      color: #888;

      &:hover {
        color: #000;
      }
    }
  }

  .panels-wrapper {
    margin: 0 auto;

    .selected-panel {
      width: calc(100% - 425px);
      float: right;

      @media(max-width: 768px) {
        width: 100%;
      }
    }

    .table-panel {
      width: 400px;
      float: left;

      @media(max-width: 768px) {
        width: 100%;
      }
    }
  }

  .datatable-pager {
    display: none;
  }

  .bottom-loading {
    .progress-linear {
      position: fixed !important;
      bottom: 35px;
    }
  }

  .list-header {
    background: #eee;

    h4 {
      margin-bottom: 0;
    }

    button {
      padding-top: 0;
      padding-bottom: 0;
    }
  }
}

section.btn-toolbar {
  height: unset;
  min-height: unset;
  padding: 0.25rem 0;
  border: 1px solid var(--neutral-200);
  border-right: none;
  border-left: none;
}
