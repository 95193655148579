// $breakpoints: (
//   "xs": 0,
//   "sm": 576px,
//   "md": 768px,
//   "lg": 992px,
//   "xl": 1200px,
//   "xxl": 1400px
// );

// $grid-columns: (
//   1: 8.333333%,
//   2: 16.666667%,
//   3: 25%,
//   4: 33.333333%,
//   5: 41.666667%,
//   6: 50%,
//   7: 58.333333%,
//   8: 66.666667%,
//   9: 75%,
//   10: 83.333333%,
//   11: 91.666667%,
//   12: 100%
// );

// @each $column, $value in $grid-columns {
//   .col-#{$column} {
//     width: $value;
//     float: left;
//     position: relative;
//     min-height: 1px;
//   }

//   /**
//   * Resetar os estilos das classes col-xs-* que não existem mais a partir
//   * do Bootstrap 4.
//   */
//   .col-xs-#{$column}, .col-xs-offset-#{$column}, .col-xs-push-#{$column}, .label {
//     all: unset !important;
//   }

// }

// .text-start {
//   text-align: left !important;
// }

// .text-end {
//   text-align: right !important;
// }

// .text-muted {
//   all: unset !important;
// }

// .text-start {
//   text-align: left!important;
// }

// .text-end {
//   text-align: right!important;
// }

// /**
//   * Resetar os estilos da classe label,hidden, pull-left e pull-start que não existem mais a partir
//   * do Bootstrap 4.
//   */
// .text-left, .text-right, .hidden, .pull-left, .pull-right {
//   all: unset !important;
// }


// .rounded {
//   border-radius: 6px;
// }

// .rounded-circle {
//   border-radius: 50%;
// }

// .img-responsive, .img-rounded, .img-circle {
//   all: unset !important;
// }

// .table.table-sm > tbody > tr > td,
// .table.table-sm > tbody > tr > th,
// .table.table-sm > tfoot > tr > td,
// .table.table-sm > tfoot > tr > th,
// .table.table-sm > thead > tr > td,
// .table.table-sm > thead > tr > th {
//   padding: 5px;
// }

// .table-condensed {
//   all: unset !important;
// }

// .form-control-sm {
//   height: 30px;
//   padding: 5px 10px;
//   font-size: var(--font-size-sm);
//   line-height: 1.5;
//   border-radius: 3px;
// }

// .form-control-lg {
//   height: 46px;
//   padding: 10px 16px;
//   font-size: var(--font-size-xl);
//   line-height: 1.3333333;
//   border-radius: 6px;
// }

// .input-sm, .input-lg {
//   all: unset !important;
// }

// .form-text {
//   display: block;
//   margin-top: 5px;
//   margin-bottom: 10px;
// }

// .help-block {
//   all: unset !important;
// }

// .mx-auto {
//   display: block;
//   margin-left: auto;
//   margin-right: auto;
// }

// .center-block {
//   all: unset !important;
// }

// .dropdown-toggle::after {
//   display: inline-block;
//   margin-left: 0.255em;
//   vertical-align: 0.255em;
//   content: "";
//   border-top: 0.3em solid;
//   border-right: 0.3em solid transparent;
//   border-bottom: 0;
//   border-left: 0.3em solid transparent;
// }

// .caret {
//   all: unset !important;
// }

// $font-weights: (
//   "bold": bold,
//   "bolder": bolder,
//   "semibold": 600,
//   "medium": 500,
//   "normal": normal,
//   "light": 300,
//   "lighter": lighter
// );

// @each $weight, $value in $font-weights {
//   .fw-#{$weight} {
//     font-weight: $value !important;
//   }

//   .font-weight-#{$weight} {
//     font-weight: unset !important;
//   }
// }

// $font-styles: normal, italic;

// @each $style in $font-styles {
//   .fst-#{$style} {
//     font-style: $style !important;
//   }
// }

// .btn-close {
//   --bs-btn-close-color: #000;
//   --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
//   --bs-btn-close-opacity: 0.5;
//   --bs-btn-close-hover-opacity: 0.75;
//   --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
//   --bs-btn-close-focus-opacity: 1;
//   --bs-btn-close-disabled-opacity: 0.25;
//   --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);

//   box-sizing: content-box;
//   width: 1em;
//   height: 1em;
//   padding: .25em .25em;
//   color: var(--bs-btn-close-color);
//   background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
//   border: 0;
//   border-radius: .375rem;
//   opacity: var(--bs-btn-close-opacity);

//   &:hover {
//     color: var(--bs-btn-close-color);
//     text-decoration: none;
//     opacity: var(--bs-btn-close-hover-opacity);
//   }

//   &:focus {
//     outline: 0;
//     box-shadow: var(--bs-btn-close-focus-shadow);
//     opacity: var(--bs-btn-close-focus-opacity);
//   }
// }

// .modal-header {
//   --bs-border-color: #495057;
//   --bs-border-radius-lg: 0.5rem;
//   --bs-border-width: 1px;

//   --bs-modal-header-padding: 1rem 1rem;
//   --bs-modal-header-border-width: var(--bs-border-width);
//   --bs-modal-header-border-color: var(--bs-border-color);
//   --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - (var(--bs-border-width)));

//   display: flex;
//   flex-shrink: 0;
//   align-items: center;
//   justify-content: space-between;
//   padding: var(--bs-modal-header-padding);
//   border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
//   border-top-left-radius: var(--bs-modal-inner-border-radius);
//   border-top-right-radius: var(--bs-modal-inner-border-radius);

//   .btn-close {
//     --bs-modal-header-padding-y: 1rem;
//     --bs-modal-header-padding-x: 1rem;

//     padding: calc(var(--bs-modal-header-padding-y) * .5) calc(var(--bs-modal-header-padding-x) * .5);
//     margin: calc(-.5 * var(--bs-modal-header-padding-y)) calc(-.5 * var(--bs-modal-header-padding-x)) calc(-.5 * var(--bs-modal-header-padding-y)) auto;
//   }
// }

// .close {
//   all: unset !important;
// }

// @each $breakpoint, $minWidth in $breakpoints {
//   $infix: if($minWidth == 0, '', "-#{$breakpoint}");

//   @media (min-width: $minWidth) {
//     /**
//      * Display modes
//      */
//     $displays: inline, inline-block, block, table, table-cell, table-row, flex, inline-flex, grid, none;
//     @each $display in $displays {
//       .d#{$infix}-#{$display} {
//         display: $display !important;
//       }
//     }
//     /**
//      * Flex directions
//      */
//     $flexDirections: row, row-reverse, column, column-reverse;
//     @each $direction in $flexDirections {
//       .flex#{$infix}-#{$direction} {
//         flex-direction: $direction !important;
//       }
//     }

//     /**
//      * Justify content
//      */
//     $justifyContents:(
//       "start": flex-start,
//       "end": flex-end,
//       "center": center,
//       "between": space-between,
//       "around": space-around,
//       "evenly": space-evenly
//     );

//     @each $justifyContent, $justifyContentValue in $justifyContents {
//       .justify-content#{$infix}-#{$justifyContent} {
//         justify-content: $justifyContentValue !important;
//       }
//     }

//       /**
//      * Align items
//      */

//      $alignItems:(
//       "start": flex-start,
//       "end": flex-end,
//       "center": center,
//       "baseline": baseline,
//       "stretch": stretch
//     );

//      @each $alignItem, $alignItemValue in $alignItems {
//        .align-items#{$infix}-#{$alignItem} {
//          align-items: $alignItemValue !important;
//        }
//      }

//     /**
//      * Float directions
//      */
//     $floatDirections: (
//       start: left,
//       end: right
//     );
//     @each $direction, $value in $floatDirections {
//       .float#{$infix}-#{$direction} {
//         float: $value !important;
//       }
//     }
//   }
// }

/**
  * Bootstrap 3 e 4
  * Classes que foram descontinuadas e ainda são usadas no sistema
  */

.btn-group-justified {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
}

.btn-group-xs {
  & > .btn {
    @extend .btn-xs;
  }
}

.btn.btn-xs {
  --bs-btn-padding-x: 0.375rem;
  --bs-btn-padding-y: 0.25rem;
  --bs-btn-font-size: var(--font-size-sm);
  --bs-btn-border-radius: 0.25rem;
  --bs-btn-line-height: 1;
}

.panel {
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 0.375rem;
  box-shadow: 0 1px 1px rgba(0,0,0,.05);

  &.panel-default {
    border-color: #ddd;
  }

  .panel-heading {
    color: #333;
    background-color: #f5f5f5;
    padding: 0.5rem 1rem;
    border-bottom: 1px solid #ddd;
    border-top-left-radius: 0.375rem;
    border-top-right-radius: 0.375rem;
  }

  .panel-body {
    padding: 1rem;
  }

  .panel-footer {
    padding: 0.5rem 1rem;
    background-color: #f5f5f5;
    border-top: 1px solid #ddd;
    border-bottom-right-radius: 0.375rem;
    border-bottom-left-radius: 0.375rem;
  }
}

.img-fluid {
  max-width: 100%;
  height: auto;
  display: block;
}

.checkbox-inline, .radio-inline {
  position: relative;
  display: inline-block;
  padding-left: 20px;
  margin-bottom: 0;
  font-weight: 400;
  vertical-align: middle;
  cursor: pointer;
}

.checkbox input[type=checkbox],
.checkbox-inline input[type=checkbox],
.radio input[type=radio],
.radio-inline input[type=radio] {
  position: absolute;
  margin-top: 4px;
  margin-left: -20px;
}

.checkbox-inline + .checkbox-inline,
.radio-inline + .radio-inline {
  margin-top: 0;
  margin-left: 10px;
}
