.sale-page {
  $free-green: var(--green-500);
  $premium-orange: #FFC107;
  $flag-red: #e93333;

  padding-bottom: 50px;

  .discount-flag {
    background: $flag-red;
    float: left;
    width: 120px;
    text-align: center;
    color: #fff;
    padding: 13px 0 8px;
    margin-top: 23px;
    position: relative;
    font-size: 1.2em;
  }

  .discount-flag:after {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(201,0,122,0);
    border-top-color: $flag-red;
    border-width: 20px;
    margin-left: -20px;
    -ms-transform: scale(3, 1);
    -webkit-transform: scale(3, 1);
    transform: scale(3, 1);
  }

  .discount-flag-aside {
    margin-left: 140px;
    padding-top: 15px;
  }

  .discount-value {
    font-size: 2.4em;
    line-height: 1em;
    font-weight: 600;
  }

  .discount-coupon {
    font-size: var(--font-size-sm);
  }

  .top-right-panel {
    float: right;
  }

  @media (min-width: 768px) {
    .feature {
      height: 240px;
    }
  }

  .feature {
    border-radius: 2px;
    background: #fff;
    padding: 20px;
    text-align: center;
    margin-bottom: 30px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    position: relative;

    .feature-icon-wrapper {
      display: block;
      vertical-align: middle;
      margin-bottom: 20px;
      text-align: center;
      margin-top: 15px;
    }

    .plan-label {
      position: absolute;
      top: 15px;
      left: 0;
      font-size: larger;
      color: white;
      border-bottom-right-radius: 17px;
      border-top-right-radius: 17px;
      padding: 5px 10px 5px 6px;
    }

    .free-plan {
      background-color: $free-green;
    }

    .premium-plan {
      background-color: $premium-orange;
      font-weight: bold;
    }
  }

  .top-feature {
    margin-top: 19px;
    cursor: pointer;

    .top-text {
      font-size: var(--font-size-lg);
      font-weight: bold;
      padding-left: 28px
    }

    .feature-content {
      padding: 15px;
      background: var(--primary);
      color: white;
      border-radius: 35px;

      .feature-icon-wrapper {
        display: inline-block;
        text-align: center;
        width: calc(40% - 4px);
        border-color: white;
        border-right-style: solid;
        border-right-width: thin;
        vertical-align: middle;
      }

      .feature-title {
        font-size: var(--font-size-xl);
      }

      .feature-banner {
        display: inline-block;
        text-align: center;
        width: 60%;
        vertical-align: middle;

        .feature-banner-title {
          font-size: x-large;
          text-align: center;
        }

        .feature-banner-subtitle {
          font-size: medium;
          text-align: center;
        }
      }
    }
  }

  .feature-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    text-align: center;

    .old-price {
      color: #babec5;
      text-decoration: line-through;
      font-size: small;
      vertical-align: middle;
    }

    .real-price {
      margin: 0 6px;
      font: normal normal 2.4em/1.2em "Geogrotesque","Calibri","Trebuchet MS",sans-serif;
      vertical-align: middle;
    }

    .by-month {
      font-size: small;
      margin-right: 15px;
      vertical-align: middle;
    }
  }

  .btn-premium {
    background-color: var(--primary);
    color: white;

    &:hover {
      background-color: #c59811;
    }
  }
}
