// Global css classes
@import "../../../node_modules/font-awesome/scss/font-awesome.scss";
@import "app/theme/mixins/responsive-screens.mixins";

* {
  font-family: Roboto,system-ui,-apple-system,"Segoe UI", Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
  accent-color: var(--primary-500);
}

$primary: #9500ff;
$secondary: #32DB64;
$danger: #f53d3d;
$light: #f4f4f4;
$dark: #222;
$favorite: #69BB7B;
$econdos-dark: #5A5A5A;
$econdos-green: #439447;
$econdos-red: #FF5722;
$econdos-blue: #387ef5;
$econdos-lightgray: #eae8e8;
$econdos-gray: #bdbdbd;
$facebook-gray: #90949c;
$facebook-blue: #365899;
$liked-button: #5890FF;
$error: maroon;

body, section {
  min-height: 100vh;
  height: 100vh;
  font-size: var(--font-size-base);
  color: #333;
}

h1 {
  font-size: 2.5rem;
}

h2 {
  font-size: var(--font-size-4xl);
}

h3 {
  font-size: var(--font-size-3xl);
}

h4 {
  font-size: var(--font-size-xl);
}

h5 {
  font-size: var(--font-size-base);
}

.bold {
  font-weight: bold;
}

.env-label {
  text-transform: uppercase;
  color: red;
  font-size: var(--font-size-2xl);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 100;
}

.hasNavBar {
  padding-top: 60px;
}

.econdos-wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
  min-height: calc(100vh - 3.25rem);

  .content {
    width: 100%;
    padding-bottom: 3rem;
  }

  .content.has-side-navbar {
    padding-top: 0.5rem;
  }

}

@media(min-width: 769px) {
  .has-side-navbar {
    width: calc(100vw - 5rem) !important;
  }
}

body {
  background: var(--neutral-100);
}

.no-background {
  background: none;
}

.no-margin {
  margin: 0;
}

.info-label {
  white-space: initial;
}

.align-center {
  text-align: center;
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.float-right {
  float: right;
}

.float-left {
  float: left;
}

.margin-zero {
  margin: 0 auto;
}

.padding-zero {
  padding: 0;
}

.padding-right-zero {
  padding-right: 0;
}

.padding-left-zero {
  padding-left: 0;
}

.padding-bottom {
  padding-bottom: 15px;
}

.padding-top {
  padding-top: 15px;
}

.padding-left {
  padding-left: 15px;
}

.padding-right {
  padding-right: 15px;
}

.no-padding-horizontal {
  padding-left: 0;
  padding-right: 0;
}

.no-padding-vertical {
  padding-top: 0;
  padding-bottom: 0;
}

.padding-horizontal {
  padding-right: 10px;
  padding-left: 10px;
}

.padding-vertical {
  padding-top: 10px;
  padding-bottom: 10px;
}

.padding {
  padding: 10px;
}

@media (max-width: 978px) {
  .no-padding-xs {
    padding: 0px;
  }
}

.primary {
  color: $primary;
}

.secondary {
  color: $secondary;
}

.danger {
  color: $danger;
}

.light {
  color: $light;
}

.dark {
  color: $dark;
}

.favorite {
  color: $favorite;
}

.econdos-dark {
  color: $econdos-dark;
}

.econdos-green {
  color: $econdos-green;
}

.econdos-red {
  color: $econdos-red;
}

.econdos-blue {
  color: $econdos-blue;
}

.econdos-lightgray {
  color: $econdos-lightgray;
}

.lightgray-background {
  background: $econdos-lightgray;
}

.econdos-gray {
  color: $econdos-gray;
}

.facebook-gray {
  color: $facebook-gray;
}

.facebook-blue {
  color: $facebook-blue;
}

.liked-button {
  color: $liked-button;
}

.error {
  color: var(--red-500);
}

.modal-header {
  //background: var(--modal-header-background);
  //border-radius: 4px 4px 0 0;

  .modal-title {
    //color: var(--modal-header-text);
    font-size: var(--font-size-xl);
  }

  .close {
    //color: var(--modal-header-text);
    //opacity: .5;

    &:hover {
      //opacity: .8;
    }
  }
}

.modal-dialog {
  display: flex;
  align-items: center;
  min-height: calc(100% - 30px * 2);

  @include screen-sm {
    --bs-modal-width: 37.5rem;

    &.modal-sm {
      --bs-modal-width: 18.75rem;
    }
  }

  @include screen-md {
    &.modal-lg {
      --bs-modal-width: 56.25rem;
    }
  }

  @include screen-lg {
    &.modal-xl {
      --bs-modal-width: 75rem;
    }
  }

  .modal-content {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
}

.confirm-modal {
  margin-top: 30vh;
}

.pictures-scroll {
  margin: 0;
  padding: 0;
  white-space: nowrap;
  overflow-x: auto;
  overflow-y: visible;

  li {
    display: inline-block;
    margin: 5px;
    vertical-align: middle;
    vertical-align: top;
    position: relative;

    img {
      width: auto;
      max-height: 80px;
    }

    i {
      color: var(--primary);
      position: absolute;
      top: calc(50% - 28px);
      left: calc(50% - 24px);
    }

    .mini-loader, .upload-button button {
      width: 80px;
      height: 80px;
      display: inline-block;
      margin: 0 5px;

      span {
        white-space: normal;
      }
    }

    .remove-image-icon {
      position: absolute;
      margin-top: 4px;
      margin-left: 5px;
      font-size: var(--font-size-3xl);
      color: #F99F1B;
      cursor: pointer;
    }
  }
}

.item-span {

  display: inline-flex;
  background: #e9ebee;
  border: 1px solid #9cb4d8;
  border-radius: 2px;
  color: #162643;
  cursor: default;
  height: 1.6em;
  margin: 0 4px 2px 0;
  padding: 0 3px 0 3px;
  position: relative;
  white-space: nowrap;

  .item-remove {
    margin-left: 7px;
  }
}

.ellipsize {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.btn-circle {
  width: 30px;
  height: 30px;
  text-align: center;
  padding: 6px 0;
  font-size: var(--font-size-sm);
  line-height: 1.428571429;
  border-radius: 50%;

  &.btn-big {
    width: 40px;
    height: 40px;
    font-size: var(--font-size-3xl);
    line-height: 1;
  }

  &.btn-lg {
    width: 50px;
    height: 50px;
    padding: 10px 16px;
    font-size: var(--font-size-xl);
    line-height: 1.33;
  }

  &.btn-xl {
    width: 70px;
    height: 70px;
    padding: 10px 16px;
    font-size: var(--font-size-3xl);
    line-height: 1.33;
  }

}

@keyframes fa-blink {
  0% {
    opacity: 1;
  }
  25% {
    opacity: 0.75;
  }
  50% {
    opacity: 0.5;
  }
  75% {
    opacity: 0.25;
  }
  100% {
    opacity: 0.5;
  }
}

.fa-blink {
  -webkit-animation: fa-blink 1.75s linear infinite;
  -moz-animation: fa-blink 1.75s linear infinite;
  -ms-animation: fa-blink 1.75s linear infinite;
  -o-animation: fa-blink 1.75s linear infinite;
  animation: fa-blink 1.75s linear infinite;
}

.cursor-pointer {
  cursor: pointer;
}

$lines: 2, 3, 4, 5;

@each $number in $lines {
  .ellipsize-#{$number} {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: $number;
    -webkit-box-orient: vertical;
  }
}

tabset {
  .nav-tabs {
    &, &.nav-justified {
      border-bottom: 1px solid var(--neutral-300);
    }

    .nav-item {
      padding: 0;
      transition: all 0.2s;

      &:hover {
        border-color: transparent;
      }

      & > a {
        color: var(--neutral-500);
        background-color: transparent;
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        padding: 0.75rem 1rem;
        margin-right: 0;
        transition: all 0.2s;

        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.75rem;
        font-weight: 500;

        &:hover, &:focus {
          background-color: transparent;
          border-color: transparent;
          border-bottom-color: var(--neutral-500);
        }

        .fa {
          font-size: var(--font-size-lg);
          color: var(--neutral-500);
        }

        span {
          display: none;
        }
      }

      &.active {
        border-bottom-color: var(--primary-500);

        &:hover, &:focus {
          background-color: transparent;
        }

        & > a {
          &, &:hover, &:focus {
            font-weight: 500;
            color: var(--neutral-800);
            background-color: transparent;
            border-color: transparent;
            border-top: 3px solid transparent;
            border-bottom: 3px solid var(--primary-500);
          }

          .fa {
            color: var(--neutral-800);
          }
        }
      }
    }
  }
}

.btn + .btn, .btn-group + .btn, .datepicker-wrapper + .btn, .search-input + .btn, .label + .label {
  margin-left: 0.375rem;
}

.btn {
  --bs-btn-font-size: var(--font-size-base);
  --bs-btn-font-weight: 500;
  outline: none !important;
  transition: all 0.2s;

  &:has(iconify-icon) {
    --bs-btn-line-height: 1;
  }

  &.btn-sm {
    font-size: var(--font-size-sm);
  }

  &.btn-lg {
    font-size: var(--font-size-xl);
  }
}

.btn.btn-default {
  background-color: white;
  border-color: var(--neutral-200);
  color: var(--neutral-700);

  &:hover {
    background-color: var(--neutral-100);
    border-color: var(--neutral-300);
  }

  &.active, &:active, &:active:focus, &.active:focus, &.active:hover, &:focus {
    background-color: var(--neutral-200);
    border-color: var(--neutral-300);
  }

  &:disabled {
    cursor: not-allowed;
    filter: alpha(opacity = 65);
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: .65;
  }
}

.btn-admin {
  background-color: white;
  outline: 3px dashed var(--primary) !important;
  outline-offset: -3px !important;
  color: #333;

  &:hover {
    background-color: var(--neutral-100);
  }
}

a, .btn-link {
  color: var(--blue-500);
  outline: none;
  text-decoration: none;

  &:hover, &:focus {
    color: var(--blue-600);
  }
}

pagination-controls .ngx-pagination .current {
  color: var(--on-primary-500);
  background-color: var(--primary-500);
}

.border {
  border: 1px solid var(--neutral-200);
}

$font-sizes: (
  'xxs': 0.625rem, // 10px
  'xs': 0.6875rem, // 11px
  'sm': 0.75rem, // 12px
  'base': 0.875rem, // 14px
  'lg': 1rem, // 16px
  'xl': 1.125rem, // 18px
  '2xl': 1.25rem, // 20px
  '3xl': 1.5rem, // 24px
  '4xl': 2rem, // 32px
  '5xl': 3rem, // 48px
  '6xl': 3.5rem, // 56px
);

@each $size, $value in $font-sizes {
  :root {
    --font-size-#{$size}: #{$value};
  }

  .text-#{$size} {
    font-size: #{'var(--font-size-#{$size})'} !important;
  }
}

$colors-variables: (
  // Whitelabel colors
  "app": "--primary",
  "on-primary": "--on-primary",

  // Bootstrap colors
  // Substituímos as cores do Bootstrap pelas do Tailwind
  "primary": "--blue",
  "secondary": "--neutral",
  "success": "--green",
  "warning": "--yellow",
  "danger": "--red",
  "info": "--cyan",

  // Tailwind CSS colors
  "slate": "--slate",
  "gray": "--gray",
  "zinc": "--zinc",
  "neutral": "--neutral",
  "stone": "--stone",
  "red": "--red",
  "orange": "--orange",
  "amber": "--amber",
  "yellow": "--yellow",
  "lime": "--lime",
  "green": "--green",
  "emerald": "--emerald",
  "teal": "--teal",
  "cyan": "--cyan",
  "sky": "--sky",
  "blue": "--blue",
  "indigo": "--indigo",
  "violet": "--violet",
  "purple": "--purple",
  "fuchsia": "--fuchsia",
  "pink": "--pink",
  "rose": "--rose"
);

$colors-shades: 50, 100, 200, 300, 400, 500, 600, 700, 800, 900;

// Criando rings
@each $color, $variable in $colors-variables {
  :root {
    @each $shade in $colors-shades {
      --ring-#{$color}-#{$shade}: #{'0 0 0 1px var(#{$variable}-#{$shade})'};
      --ring-2-#{$color}-#{$shade}: #{'0 0 0 2px var(#{$variable}-#{$shade})'};
      --ring-3-#{$color}-#{$shade}: #{'0 0 0 3px var(#{$variable}-#{$shade})'};
      --ring-4-#{$color}-#{$shade}: #{'0 0 0 4px var(#{$variable}-#{$shade})'};
    }
  }

  @each $shade in $colors-shades {
    .ring-#{$color}-#{$shade} {
      box-shadow: #{'var(--ring-#{$color}-#{$shade})'};
    }

    .ring-2-#{$color}-#{$shade} {
      box-shadow: #{'var(--ring-2-#{$color}-#{$shade})'};
    }

    .ring-3-#{$color}-#{$shade} {
      box-shadow: #{'var(--ring-3-#{$color}-#{$shade})'};
    }

    .ring-4-#{$color}-#{$shade} {
      box-shadow: #{'var(--ring-4-#{$color}-#{$shade})'};
    }
  }
}

@each $color, $variable in $colors-variables {
  $colorContrast: "var(--on-primary)";

  @if $color != "app" {
    $colorContrast: "#FFFFFF";
  }

  .btn-#{$color} {
    &, &:focus {
      color: #{$colorContrast};
      background-color: #{"var(#{$variable}-500)"};
      background-image: none;
      border-color: #{"var(#{$variable}-500)"};
    }

    &:focus {
      box-shadow: #{'var(--ring-3-#{$color}-300)'};
    }

    &:hover {
      color: #{$colorContrast};
      background-color: #{"var(#{$variable}-600)"};
      border-color: #{"var(#{$variable}-600)"};
    }

    &.active, &:active, &:active:focus, &.active:focus, &.active:hover {
      color: #{$colorContrast};
      background-color: #{"var(#{$variable}-600)"};
      border-color: #{"var(#{$variable}-600)"};
    }

    &.disabled, &:disabled, fieldset:disabled & {
      --bs-btn-disabled-color: #{$colorContrast};
      --bs-btn-disabled-bg: #{"var(#{$variable}-400)"};
      --bs-btn-disabled-border-color: #{"var(#{$variable}-400)"};
    }
  }

  .btn-outline-#{$color} {
    &, &:focus {
      color: #{"var(#{$variable}-500)"};
      background-color: transparent;
      background-image: none;
      border-color: #{"var(#{$variable}-500)"};
    }

    &:focus {
      box-shadow: #{'var(--ring-3-#{$color}-300)'};
    }

    &:hover {
      color: #{$colorContrast};
      background-color: #{"var(#{$variable}-500)"};
      border-color: #{"var(#{$variable}-500)"};
    }

    &.active, &:active, &:active:focus, &.active:focus, &.active:hover {
      color: #{$colorContrast};
      background-color: #{"var(#{$variable}-600)"};
      border-color: #{"var(#{$variable}-600)"};
    }
  }

  .btn-subtle-#{$color} {
    &, &:focus {
      color: #{"var(#{$variable}-600)"};
      background-color: #{"var(#{$variable}-100)"};
      background-image: none;
      border-color: #{"var(#{$variable}-100)"};
    }

    &:focus {
      box-shadow: #{'var(--ring-3-#{$color}-200)'};
    }

    &:hover {
      color: #{"var(#{$variable}-700)"};
      background-color: #{"var(#{$variable}-200)"};
      border-color: #{"var(#{$variable}-200)"};
    }

    &.active, &:active, &:active:focus, &.active:focus, &.active:hover {
      color: #{"var(#{$variable}-800)"};
      background-color: #{"var(#{$variable}-300)"};
      border-color: #{"var(#{$variable}-300)"};
    }

    &.disabled, &:disabled, fieldset:disabled & {
      --bs-btn-disabled-color: #{"var(#{$variable}-500)"};
      --bs-btn-disabled-bg: #{"var(#{$variable}-50)"};
      --bs-btn-disabled-border-color: #{"var(#{$variable}-50)"};
    }
  }

  .btn-ghost-#{$color} {
    &, &:focus {
      color: #{"var(#{$variable}-500)"};
      background-color: transparent;
      background-image: none;
      border-color: transparent;
    }

    &:focus {
      box-shadow: #{'var(--ring-3-#{$color}-200)'};
    }

    &:hover {
      color: #{"var(#{$variable}-600)"};
      background-color: #{"var(#{$variable}-100)"};
      border-color: #{"var(#{$variable}-100)"};
    }

    &.active, &:active, &:active:focus, &.active:focus, &.active:hover {
      color: #{"var(#{$variable}-700)"};
      background-color: #{"var(#{$variable}-200)"};
      border-color: #{"var(#{$variable}-200)"};
    }

    &.disabled, &:disabled, fieldset:disabled & {
      --bs-btn-disabled-color: #{"var(#{$variable}-300)"};
      --bs-btn-disabled-bg: transparent;
      --bs-btn-disabled-border-color: transparent;
    }
  }

  .btn-gradient-#{$color} {
    &, &:focus {
      color: #{$colorContrast};
      background-color: transparent;
      background-image: none;
      background: linear-gradient(to right, #{"var(#{$variable}-500)"} 50%, #{"var(#{$variable}-400)"} 100%);
      border: none;
    }

    &:focus {
      box-shadow: #{'var(--ring-3-#{$color}-300)'};
    }

    &:hover {
      color: #{$colorContrast};
      background: linear-gradient(to right, #{"var(#{$variable}-600)"} 50%, #{"var(#{$variable}-500)"} 100%);
      border: none;
    }

    &.active, &:active, &:active:focus, &.active:focus, &.active:hover {
      color: #{$colorContrast};
      background: linear-gradient(to right, #{"var(#{$variable}-700)"} 50%, #{"var(#{$variable}-600)"} 100%);
      border: none;
    }

    &.disabled, &:disabled, fieldset:disabled & {
      --bs-btn-disabled-color: #{$colorContrast};
      --bs-btn-disabled-bg: linear-gradient(to right, #{"var(#{$variable}-300)"} 50%, #{"var(#{$variable}-200)"} 100%);
      --bs-btn-disabled-border-color: transparent;
    }
  }

  .btn-link-#{$color} {
    &, &:focus {
      color: #{"var(#{$variable}-500)"};
      background-color: transparent;
      background-image: none;
      border: none;
      padding: 0;
    }

    &:hover {
      color: #{"var(#{$variable}-600)"};
      background-color: transparent;
      background-image: none;
      border: none;
      padding: 0;
      text-decoration: underline;
    }

    &:active {
      color: #{"var(#{$variable}-700)"};
      box-shadow: none;
    }

    &.disabled, &:disabled, fieldset:disabled & {
      --bs-btn-disabled-color: #{"var(#{$variable}-400)"};
      --bs-btn-disabled-bg: transparent;
      --bs-btn-disabled-border-color: transparent;
    }
  }

  .alert-#{$color} {
    color: #{"var(#{$variable}-600)"} !important;
    background-color: #{"var(#{$variable}-100)"} !important;
    border-color: #{"var(#{$variable}-200)"} !important;
  }

  .badge-#{$color} {
    font-weight: 500 !important;
    background-color: #{"var(#{$variable}-500)"} !important;
    background-image: none !important;
    color: #{$colorContrast} !important;
  }

  .badge-outline-#{$color} {
    font-weight: 500 !important;
    color: #{"var(#{$variable}-500)"} !important;
    background-color: transparent !important;
    border: 1px solid #{"var(#{$variable}-500)"} !important;
  }

  .badge-subtle-#{$color} {
    font-weight: 500 !important;
    color: #{"var(#{$variable}-600)"} !important;
    background-color: #{"var(#{$variable}-100)"} !important;
  }

  .text-#{$color} {
    color: #{"var(#{$variable}-500)"} !important;
  }

  .bg-#{$color} {
    background-color: #{"var(#{$variable}-500)"} !important;
  }

  .border-#{$color} {
    border-color: #{"var(#{$variable}-500)"} !important;
  }

  @each $shade in $colors-shades {
    .text-#{$color}-#{$shade} {
      color: #{"var(#{$variable}-#{$shade})"} !important;
    }

    .bg-#{$color}-#{$shade} {
      background-color: #{"var(#{$variable}-#{$shade})"} !important;
    }

    .border-#{$color}-#{$shade} {
      border-color: #{"var(#{$variable}-#{$shade})"} !important;
    }
  }

  .progress-bar-#{$color} {
    color: #{$colorContrast} !important;
    background-color: #{"var(#{$variable}-500)"} !important;
  }

  .list-group-item-#{$color} {
    color: #{"var(#{$variable}-500)"} !important;
    background-color: #{"var(#{$variable}-100)"} !important;

    &:hover {
      color: #{"var(#{$variable}-600)"} !important;
      background-color: #{"var(#{$variable}-100)"} !important;
    }
  }

  .panel-#{$color} {
    border-color: #{"var(#{$variable}-100)"} !important;

    & > .panel-heading {
      color: #{"var(#{$variable}-600)"} !important;
      background-color: #{"var(#{$variable}-100)"} !important;
      border: none !important;
    }
  }
}


.swal2-modal {
  .swal2-buttonswrapper:not(.swal2-loading) .swal2-confirm {
    color: var(--on-primary-500) !important;
    background-color: var(--primary-500) !important;
    background-image: none !important;
    border: 1px solid var(--primary-500) !important;

    &:hover {
      color: var(--on-primary-500) !important;
      background-color: var(--primary-600) !important;
      border-color: var(--primary-600) !important;
    }
  }

  .swal2-cancel {
    border: 1px solid #ccc !important;
    color: #333 !important;
    background: #fff !important;

    &:hover {
      background: #e6e6e6 !important;
    }
  }

  .swal2-loading .swal2-confirm {
    border-left-color: var(--primary-500) !important;
    border-right-color: var(--primary-500) !important;
  }
}

.list-group-item.active {
  background-color: var(--primary-500);
  border-color: var(--primary-500);
  color: var(--on-primary-500);

  &:hover {
    background-color: var(--primary-600);
    border-color: var(--primary-600);
    color: var(--on-primary-600);
  }
}

.uppercase {
  text-transform: uppercase;
}

.padding-zero {
  padding: 0;
}

.btn-wrap{
  white-space: normal;
  text-align: left;
}

// Fix ngx-datatable render issue com Chrome 70
.datatable-row-group {
  will-change: transform;
}

.hardware-warning-toast {
  .wrapper {
    display: flex;
    align-items: center;

    .text-wrapper {
      flex: 1;

      .title {
        font-weight: bold;
      }
    }
  }
}

.hardware-event-toast {

  background: #5697fa;
  padding-left: 10px !important;

  &.panic {
    background: #dc3545 !important;
  }

  .wrapper {
    display: flex;
    align-items: center;

    .user-image {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      margin-right: 10px;
    }

    .text-wrapper {
      flex: 1;

      .title {
        font-weight: bold;
      }
    }
  }
}

.new-update {
  background-color: #28a745 !important;
  text-align: center;
  font-size: var(--font-size-lg);
  color: #ffffff;

  b {
    text-transform: uppercase;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}

.row.display-flex {
  display: flex;
  flex-wrap: wrap;
}

.row.display-flex > [class*='col-'] {
  display: flex;
  flex-direction: column;
}

.leading-none {
  line-height: 1;
}

.w-full {
  width: 100%;
}
.w-half {
  width: 50%;
}

.w-screen {
  width: 100vw;
}

.w-fit {
  width: fit-content;
}

.max-w-full {
  max-width: 100%;
}

.min-h-screen {
  min-height: 100vh;
}

.min-h-fit {
  min-height: fit-content;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.h-fit {
  height: fit-content;
}

.h-unset {
  height: unset;
}

.bg-white {
  background-color: #FFFFFF;
}

.text-white {
  color: #FFFFFF;
}

.shadow {
  box-shadow: var(--shadow) !important;
}

.grid-cols-2 {
  grid-template-columns: repeat(2, 1fr);
}

.d-dynamic-grid {
  /**
    * Source https://css-tricks.com/an-auto-filling-css-grid-with-max-columns/
    * User input values.
    */
  --grid-layout-gap: 0.75rem;
  --grid-column-count: 4;
  --grid-item--min-width: 160px;

  /**
    * Calculated values.
    */
  --gap-count: calc(var(--grid-column-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc((100% - var(--total-gap-width)) / var(--grid-column-count));

  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr));
  grid-gap: var(--grid-layout-gap);

  /**
    * Quando usar essa classe, lembre-se de sobrescrever as seguintes variáveis CSS:
    * --grid-item--min-width
    * --grid-item--max-width (opcional)
    * --grid-column-count (opcional)
    * --grid-layout-gap (opcional)
    */
}

.flex-1 {
  flex: 1;
}

.flex-2 {
  flex: 2;
}

.flex-3 {
  flex: 3;
}

.justify-self-start {
  justify-self: flex-start;
}

.justify-self-end {
  justify-self: flex-end;
}

.aspect-square {
  aspect-ratio: 1 / 1;
}

.aspect-video {
  aspect-ratio: 16 / 9;
}

.aspect-auto {
  aspect-ratio: auto;
}

@mixin lines-limit {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.no-of-lines-1 {
  @include lines-limit;
  -webkit-line-clamp: 1;
}

.no-of-lines-2 {
  @include lines-limit;
  -webkit-line-clamp: 2;
}

.modal-full {
  min-width: calc(100% - 3.75rem);
}

@media (min-width: 768px) and (max-width: 991px) {
  .modal-control-id {
    width: 46.25rem;
  }
}

@import "../../../node_modules/ag-grid-community/src/styles/ag-grid.scss";
@import "../../../node_modules/ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin.scss";
@import "../../../node_modules/ag-grid-community/src/styles/ag-theme-balham/sass/ag-theme-balham-mixin.scss";
.ag-theme-alpine {
  @include ag-theme-alpine();
}

.ag-theme-balham {
  @include ag-theme-balham();
}

.toggle-container {
  display: flex;

  &:not(:last-child){
    border-bottom: solid 1px #ccc;
  }

  .toggle-content {
    flex: 1;
    cursor: pointer;

    .toggle-title {
      flex: 1;
      margin: 4px 0;
      font-size: var(--font-size-lg);
      font-weight: bold;
      color: #444;
    }

    .toggle-description {
      margin: 2px 0;
      color: var(--neutral-500)
    }
  }

  .toggle-wrapper {
    display: flex;
    align-self: center;
    padding-right: 10px;

  }
}

.ngx-datatable.material.single-selection .datatable-body-row.active,
.ngx-datatable.material.single-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.material.multi-selection .datatable-body-row.active,
.ngx-datatable.material.multi-selection .datatable-body-row.active .datatable-row-group,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active,
.ngx-datatable.material.multi-click-selection .datatable-body-row.active .datatable-row-group {
  background-color: var(--primary-300);

  &:hover {
    background-color: var(--primary-400);
  }
}

// Fix backdrop with more than one opened modals at same time
modal-container {
  backdrop-filter: blur(4px);

  &:not(:nth-of-type(1)) {
    background: rgba(0, 0, 0, 0.5);
  }
}

.swal2-container.swal2-shown {
  backdrop-filter: blur(4px);
}

.cursor-pointer {
  cursor: pointer;
}
.dropdown-menu {
  font-size: var(--font-size-base);

  .dropdown-item {
    cursor: pointer;

    &:hover,
    &:focus,
    &:active,
    &.active,
    &.active:focus,
    &.active:hover {
      --bs-dropdown-link-hover-color: var(--on-primary);
      --bs-dropdown-link-hover-bg: var(--primary);
      --bs-dropdown-link-active-color: var(--on-primary);
      --bs-dropdown-link-active-bg: var(--primary);
    }
  }
}

.overflow-y-auto {
  overflow-y: auto;
}

.white-space-pre-line {
  white-space: pre-line;
}

.only-xm {
  @media (min-width: 768px) {
    display: none;
  }
}
