.signup-stepone {
  display: block;

  .error-message {
    color: #f82922;
    font-size: smaller;
    margin-bottom: -16px;
  }

  .form-group {
    margin-bottom: 25px;
  }

  .user-profile-picture {
    text-align: center;
    margin-bottom: 15px;

    .loader-wrapper {
      padding-top: 5vh;

      .mini-loader {
        margin: 0 auto;
        width: 50px;
        height: 50px;
      }
    }
  }

  .datepicker-container {
    width: 100%;

    .datepicker-input-container {
      width: 100%;

      .datepicker-input {
        width: calc(100% - 36px) !important;
      }

      .input-group-btn {
        width: auto;
      }
    }
  }

  .form-group {
    &.has-success {
      .datepicker-input-container {
        button {
          color: #fff;
          background-color: #449d44;
          border-color: #3c763d;
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
          background-color: var(--green-500);
          border-color: #3c763d;

          &:hover, &:active {
            background-color: #449d44;
            border-color: #398439;
          }
        }
      }
    }

    &.has-error {
      .datepicker-input-container {
        button {
          color: #fff;
          border-color: #a94442;
          background-color: var(--red-500);
          box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);

          &:hover, &:active {
            background-color: #c9302c;
            border-color: #ac2925;
          }

        }
      }

    }
  }

}
