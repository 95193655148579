.residence-access-log-modal {

  .modal-body {
    max-height: 60vh;
    overflow: auto;

    .filter-input-wrapper {
      display: flex;
      align-items: center;
      gap: 0.375rem;

      & > label {
        margin-bottom: 0;
      }

      .datepicker-wrapper {
        display: inline-block;
        vertical-align: bottom;

        .start-date-picker-input, .end-date-picker-input {
          width: 100px !important;
        }

        .input-group-btn {
          display: inline-block;
        }
      }
    }

    .ngx-datatable.scroll-vertical {
      height: calc(60vh - 100px);
    }

    .visitor-picture {
      height: 30px;
      width: 30px;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      border-radius: 50px;
    }

    .picture-link {
      &:hover {
        text-decoration: none;
      }
    }

    .top-buttons {
      padding-left: 10px;
      padding-right: 10px;
      margin-bottom: 5px;

      @media (max-width: 590px) {
        .button-text {
          display: none;
        }

        .search-input {
          width: 100%;
        }

        .search-button {
          margin-top: 24px;
        }
      }

      .col {
        margin: 5px 0;
        padding-left: 5px;
        padding-right: 5px;

        @media (min-width: 992px) {
          width: auto;
        }
      }

      .search-input {
        input {
          padding: 5px;
        }
      }
    }
  }

}
