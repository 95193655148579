/* You can add global styles to this file, and also import other style files */

@import '@angular/cdk/overlay-prebuilt.css';

@import '../node_modules/@perfectmemory/ngx-contextmenu/src/assets/stylesheets/base.scss';

@import "app/theme/app-spacing-utilities.scss";

@import "app/theme/app.globals";

@import "app/theme/app.navbar";

@import "app/theme/app.buttons";

@import "app/theme/app.inputs";

@import "app/theme/app.dashboard";

@import "app/theme/app.select";

@import "app/theme/app.toggle";

@import "app/theme/app.datepicker";

@import "app/theme/app.radio";

@import "app/theme/app.variables";

@import "app/theme/app.loader";

@import 'app/theme/app.calendar';

@import "app/pages/feed/feed";

@import "app/pages/condo/condo";

@import "app/pages/condo/residences/condo.residences";

@import "app/pages/condo/suppliers/condo.suppliers.scss";

@import "app/pages/condo/config/condo.config";

@import "app/pages/condo/residents/condo.residents";

@import "app/pages/reservation/create.local";

@import "app/pages/modal/comment.modal";

@import "app/pages/modal/residence.picker/apartment.picker.modal/apartment.picker.modal";

@import "app/pages/modal/residence.picker/house.picker.modal/house.picker.modal";

@import "app/pages/signup/signup";

@import "app/pages/signup/stepone/signup.stepone";

@import "app/pages/occurrence.creator/occurrence.creator";

@import "app/pages/occurrence.creator/occurrence.creator.file.list/files.scroll";

@import "app/components/condos.autocomplete/condos.autocomplete";

@import "app/components/create.id/create.id";

@import "app/pages/modal/webcam/webcam.modal";

@import "app/pages/modal/qrcode/qrcode.modal";

@import "app/pages/modal/create.condo.modal";

@import "app/pages/residences/residences";

@import "app/pages/gate/modal/modal.close.occurrence";

@import "app/pages/condo/condos/condo.list";

@import "app/components/notification.panel/notification.panel";

@import "app/pages/condo/suppliers/new.condo.supplier/new.condo.supplier.modal";

@import "app/pages/modal/vehicle.create.modal/vehicle.create.modal";

@import "app/pages/modal/condo.contact.picker.modal/condo.contact.picker.modal";

@import "app/pages/modal/residence.access.log.modal/residence.access.log.modal";

@import '../node_modules/ngx-bootstrap/datepicker/bs-datepicker.css';

@import "app/pages/modal/vehicle.pick.modal/vehicle.pick.modal";

@import "app/components/app.suggestion.panel/app.suggestion.panel";

@import "app/pages/sale/sale";

@import "app/pages/sale/subscription/subscription.scss";

@import 'ngx-toastr/toastr.css';

@import 'angular-calendar/css/angular-calendar.css';

html {
  font-size: 16px;
}

@media (max-width: 1080px) {
  html {
    font-size: 93.75%;
  }
}

@media (max-width: 720px) {
  html {
    font-size: 87.5%;
  }
}

.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  &.dark-backdrop {
    background: #000;
    opacity: 0.85 !important;
  }
}

div.cdk-overlay-container {
  z-index: 2000;
}

/**
 * Corrigir posicionamento do popover e tooltip
 */
.popover {
  position: absolute;
  top: 0;
  left: 0 #{"/* rtl:ignore */"};
}

.popover-arrow, .tooltip, .tooltip-arrow {
  position: absolute;
}

.tooltip {
  font-size: var(--font-size-xs);
}

:root {
  // Bootstrap colors
  // --bs-primary-50: #cee2fe;
  // --bs-primary-100: #9ec5fe;
  // --bs-primary-200: #79affd;
  // --bs-primary-300: #5599fd;
  // --bs-primary-400: #3183fd;
  // --bs-primary-500: #0d6efd;
  // --bs-primary-600: #0b5dd7;
  // --bs-primary-700: #094db1;
  // --bs-primary-800: #073c8b;
  // --bs-primary-900: #052c65;

  // --bs-secondary-50: #e1e3e5;
  // --bs-secondary-100: #c4c7cb;
  // --bs-secondary-200: #aeb3b7;
  // --bs-secondary-300: #989ea4;
  // --bs-secondary-400: #828990;
  // --bs-secondary-500: #6c757d;
  // --bs-secondary-600: #5b636a;
  // --bs-secondary-700: #4b5157;
  // --bs-secondary-800: #3b4044;
  // --bs-secondary-900: #2b2e32;

  // --bs-success-50: #d4edd9;
  // --bs-success-100: #a9dbb4;
  // --bs-success-200: #88ce98;
  // --bs-success-300: #68c17c;
  // --bs-success-400: #48b460;
  // --bs-success-500: #28a745;
  // --bs-success-600: #228d3a;
  // --bs-success-700: #1c7430;
  // --bs-success-800: #165b25;
  // --bs-success-900: #10421b;

  // --bs-warning-50: #fff2cd;
  // --bs-warning-100: #ffe69b;
  // --bs-warning-200: #ffdc76;
  // --bs-warning-300: #ffd351;
  // --bs-warning-400: #ffca2c;
  // --bs-warning-500: #ffc107;
  // --bs-warning-600: #d8a405;
  // --bs-warning-700: #b28704;
  // --bs-warning-800: #8c6a03;
  // --bs-warning-900: #664d02;

  // --bs-danger-50: #f8d6d9;
  // --bs-danger-100: #f1aeb4;
  // --bs-danger-200: #eb8f98;
  // --bs-danger-300: #e6717c;
  // --bs-danger-400: #e15360;
  // --bs-danger-500: #dc3545;
  // --bs-danger-600: #bb2d3a;
  // --bs-danger-700: #9a2530;
  // --bs-danger-800: #791d25;
  // --bs-danger-900: #58151b;

  // --bs-info-50: #d0ecf0;
  // --bs-info-100: #a2d9e2;
  // --bs-info-200: #7fcbd7;
  // --bs-info-300: #5cbdcd;
  // --bs-info-400: #39afc2;
  // --bs-info-500: #17a2b8;
  // --bs-info-600: #13899c;
  // --bs-info-700: #107180;
  // --bs-info-800: #0c5965;
  // --bs-info-900: #094049;

  // TailWind CSS colors
  --slate-50: #f8fafc;
  --slate-100: #f1f5f9;
  --slate-200: #e2e8f0;
  --slate-300: #cbd5e1;
  --slate-400: #94a3b8;
  --slate-500: #64748b;
  --slate-600: #475569;
  --slate-700: #334155;
  --slate-800: #1e293b;
  --slate-900: #0f172a;

  --gray-50: #f9fafb;
  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;
  --gray-300: #d1d5db;
  --gray-400: #9ca3af;
  --gray-500: #6b7280;
  --gray-600: #4b5563;
  --gray-700: #374151;
  --gray-800: #1f2937;
  --gray-900: #111827;

  --zinc-50: #fafafa;
  --zinc-100: #f4f4f5;
  --zinc-200: #e4e4e7;
  --zinc-300: #d4d4d8;
  --zinc-400: #a1a1aa;
  --zinc-500: #71717a;
  --zinc-600: #52525b;
  --zinc-700: #3f3f46;
  --zinc-800: #27272a;
  --zinc-900: #18181b;

  --neutral-50: #fafafa;
  --neutral-100: #f5f5f5;
  --neutral-200: #e5e5e5;
  --neutral-300: #d4d4d4;
  --neutral-400: #a3a3a3;
  --neutral-500: #737373;
  --neutral-600: #525252;
  --neutral-700: #404040;
  --neutral-800: #262626;
  --neutral-900: #171717;

  --stone-50: #fafaf9;
  --stone-100: #f5f5f4;
  --stone-200: #e7e5e4;
  --stone-300: #d6d3d1;
  --stone-400: #a8a29e;
  --stone-500: #78716c;
  --stone-600: #57534e;
  --stone-700: #44403c;
  --stone-800: #292524;
  --stone-900: #1c1917;

  --red-50: #fef2f2;
  --red-100: #fee2e2;
  --red-200: #fecaca;
  --red-300: #fca5a5;
  --red-400: #f87171;
  --red-500: #ef4444;
  --red-600: #dc2626;
  --red-700: #b91c1c;
  --red-800: #991b1b;
  --red-900: #7f1d1d;

  --orange-50: #fff7ed;
  --orange-100: #ffedd5;
  --orange-200: #fed7aa;
  --orange-300: #fdba74;
  --orange-400: #fb923c;
  --orange-500: #f97316;
  --orange-600: #ea580c;
  --orange-700: #c2410c;
  --orange-800: #9a3412;
  --orange-900: #7c2d12;

  --amber-50: #fffbeb;
  --amber-100: #fef3c7;
  --amber-200: #fde68a;
  --amber-300: #fcd34d;
  --amber-400: #fbbf24;
  --amber-500: #f59e0b;
  --amber-600: #d97706;
  --amber-700: #b45309;
  --amber-800: #92400e;
  --amber-900: #78350f;

  --yellow-50: #fefce8;
  --yellow-100: #fef9c3;
  --yellow-200: #fef08a;
  --yellow-300: #fde047;
  --yellow-400: #facc15;
  --yellow-500: #eab308;
  --yellow-600: #ca8a04;
  --yellow-700: #a16207;
  --yellow-800: #854d0e;
  --yellow-900: #713f12;

  --lime-50: #f7fee7;
  --lime-100: #ecfccb;
  --lime-200: #d9f99d;
  --lime-300: #bef264;
  --lime-400: #a3e635;
  --lime-500: #84cc16;
  --lime-600: #65a30d;
  --lime-700: #4d7c0f;
  --lime-800: #3f6212;
  --lime-900: #365314;

  --green-50: #f0fdf4;
  --green-100: #dcfce7;
  --green-200: #bbf7d0;
  --green-300: #86efac;
  --green-400: #4ade80;
  --green-500: #22c55e;
  --green-600: #16a34a;
  --green-700: #15803d;
  --green-800: #166534;
  --green-900: #14532d;

  --emerald-50: #ecfdf5;
  --emerald-100: #d1fae5;
  --emerald-200: #a7f3d0;
  --emerald-300: #6ee7b7;
  --emerald-400: #34d399;
  --emerald-500: #10b981;
  --emerald-600: #059669;
  --emerald-700: #047857;
  --emerald-800: #065f46;
  --emerald-900: #064e3b;

  --teal-50: #f0fdfa;
  --teal-100: #ccfbf1;
  --teal-200: #99f6e4;
  --teal-300: #5eead4;
  --teal-400: #2dd4bf;
  --teal-500: #14b8a6;
  --teal-600: #0d9488;
  --teal-700: #0f766e;
  --teal-800: #115e59;
  --teal-900: #134e4a;

  --cyan-50: #ecfeff;
  --cyan-100: #cffafe;
  --cyan-200: #a5f3fc;
  --cyan-300: #67e8f9;
  --cyan-400: #22d3ee;
  --cyan-500: #06b6d4;
  --cyan-600: #0891b2;
  --cyan-700: #0e7490;
  --cyan-800: #155e75;
  --cyan-900: #164e63;

  --sky-50: #f0f9ff;
  --sky-100: #e0f2fe;
  --sky-200: #bae6fd;
  --sky-300: #7dd3fc;
  --sky-400: #38bdf8;
  --sky-500: #0ea5e9;
  --sky-600: #0284c7;
  --sky-700: #0369a1;
  --sky-800: #075985;
  --sky-900: #0c4a6e;

  --blue-50: #eff6ff;
  --blue-100: #dbeafe;
  --blue-200: #bfdbfe;
  --blue-300: #93c5fd;
  --blue-400: #60a5fa;
  --blue-500: #3b82f6;
  --blue-600: #2563eb;
  --blue-700: #1d4ed8;
  --blue-800: #1e40af;
  --blue-900: #1e3a8a;

  --indigo-50: #eef2ff;
  --indigo-100: #e0e7ff;
  --indigo-200: #c7d2fe;
  --indigo-300: #a5b4fc;
  --indigo-400: #818cf8;
  --indigo-500: #6366f1;
  --indigo-600: #4f46e5;
  --indigo-700: #4338ca;
  --indigo-800: #3730a3;
  --indigo-900: #312e81;

  --violet-50: #f5f3ff;
  --violet-100: #ede9fe;
  --violet-200: #ddd6fe;
  --violet-300: #c4b5fd;
  --violet-400: #a78bfa;
  --violet-500: #8b5cf6;
  --violet-600: #7c3aed;
  --violet-700: #6d28d9;
  --violet-800: #5b21b6;
  --violet-900: #4c1d95;

  --purple-50: #faf5ff;
  --purple-100: #f3e8ff;
  --purple-200: #e9d5ff;
  --purple-300: #d8b4fe;
  --purple-400: #c084fc;
  --purple-500: #a855f7;
  --purple-600: #9333ea;
  --purple-700: #7e22ce;
  --purple-800: #6b21a8;
  --purple-900: #581c87;

  --fuchsia-50: #fdf4ff;
  --fuchsia-100: #fae8ff;
  --fuchsia-200: #f5d0fe;
  --fuchsia-300: #f0abfc;
  --fuchsia-400: #e879f9;
  --fuchsia-500: #d946ef;
  --fuchsia-600: #c026d3;
  --fuchsia-700: #a21caf;
  --fuchsia-800: #86198f;
  --fuchsia-900: #701a75;

  --pink-50: #fdf2f8;
  --pink-100: #fce7f3;
  --pink-200: #fbcfe8;
  --pink-300: #f9a8d4;
  --pink-400: #f472b6;
  --pink-500: #ec4899;
  --pink-600: #db2777;
  --pink-700: #be185d;
  --pink-800: #9d174d;
  --pink-900: #831843;

  --rose-50: #fff1f2;
  --rose-100: #ffe4e6;
  --rose-200: #fecdd3;
  --rose-300: #fda4af;
  --rose-400: #fb7185;
  --rose-500: #f43f5e;
  --rose-600: #e11d48;
  --rose-700: #be123c;
  --rose-800: #9f1239;
  --rose-900: #881337;

  // Tailwind CSS Shadows
  --shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --shadow-md: var(--bs-box-shadow);
  --shadow-lg: var(--bs-box-shadow-lg);
}
