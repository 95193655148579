@import "app/theme/mixins/responsive-screens.mixins";

.condo-config {
  max-width: 1280px;
  width: 100%;
  height: calc(100vh - 180px);
  margin: 1rem auto 0;
  background-color: white;
  border-radius: 0.75rem;
  overflow: hidden;
  box-shadow: var(--shadow);

  display: flex;
  flex-direction: column;

  @include screen-md {
    flex-direction: row;
  }

  & > aside {
    background-color: var(--neutral-50);
    border-bottom: 1px solid var(--neutral-200);

    display: flex;
    flex-direction: column;

    @include screen-md {
      flex: 3;
      border-right: 1px solid var(--neutral-200);
    }

    & > select {
      display: initial;

      @include screen-md {
        display: none;
      }
    }

    app-title {
      padding: 1.25rem 0.75rem;
      border-bottom: 1px solid var(--neutral-200);

      header {
        margin-bottom: 0 !important;

        h2 {
          line-height: 1;
        }
      }
    }

    .condo-config__nav {
      width: 100%;
      padding: 0.75rem;
      margin: 0;
      list-style: none;
      overflow-y: auto;

      display: none;

      @include screen-md {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
      }

      & > li {
        width: 100%;
        padding: 0.75rem;
        border-radius: 0.25rem;
        cursor: pointer;

        color: var(--neutral-500);

        display: flex;
        align-items: center;
        gap: 0.75rem;

        & > strong {
          font-size: var(--font-size-lg);
          font-weight: normal;
        }

        &:hover {
          background-color: var(--neutral-100);
        }

        &.condo-config__nav-item--active {
          background-color: var(--neutral-200);
          color: var(--neutral-800);
          pointer-events: none;

          & > strong {
            font-weight: bold;
          }
        }
      }
    }
  }

  & > main {
    flex: 7;
    overflow: hidden;

    display: flex;
    flex-direction: column;

    & > header {
      padding: 1.25rem 0.75rem;
      border-bottom: 1px solid var(--neutral-200);

      & > h3 {
        margin: 0;
        font-size: var(--font-size-3xl);
        font-weight: 600;
        color: var(--neutral-700);
        line-height: 1;
      }
    }

    .condo-config__params {
      flex: 1;
      overflow-y: auto;
      list-style: none;
      padding: 0.75rem;

      display: flex;
      flex-direction: column;
      gap: 0.75rem;

      & > li {
        padding: 0.75rem 0;
        border-bottom: 1px solid var(--neutral-200);

        display: flex;
        align-items: flex-start;
        gap: 2rem;

        @include screen-md {
          padding: 0.75rem;
        }

        & > iconify {
          color: var(--neutral-400);

          @include screen-md {
            font-size: var(--font-size-4xl);
          }
        }

        & > main {
          flex: 1;

          display: flex;
          flex-direction: column;
          gap: 0.25rem;

          & > strong,
          & > span {
            font-size: var(--font-size-base);
            line-height: 1.625;

            &:is(strong) {
              color: var(--neutral-700);
              font-weight: 600;
            }

            &:is(span) {
              color: var(--neutral-500);
            }
          }
        }

        & > .toggle-wrapper {
          align-self: flex-end;

          .switch.toggle.disabled {
            pointer-events: none;
            opacity: 0.6;
          }
        }

        &.condo-config__params-sub-section {
          padding: 0;
          margin-top: 0.75rem;
          border-bottom: none;

          & > h4 {
            margin: 0;

            font-size: var(--font-size-base);
            font-weight: bold;
            color: var(--neutral-500);
            line-height: 1;
          }
        }
      }
    }
  }
}
