.condo-contact-picker-modal {

  .modal-body {
    //max-height: 60vh;
    height: 60vh;
    overflow-y: auto;
  }

  .user-row {
    cursor: pointer;
    &:hover:not(.active) {
      color: #555;
      text-decoration: none;
      background-color: #f5f5f5;
    }

    .active {
      .fa {
        transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);

      }
    }

  }

  .contact-details {
    position: fixed;
    bottom: 75px;
    right: 50px;
    min-width: 40%;
    z-index: 99;
    box-shadow: 4px 4px 5px grey;
    border: none;

    .panel-heading {
      background-color: var(--primary);
    }

    .panel-body {
      //min-height: 110px;
    }
  }

}
