.residences {
  .top-buttons {
    margin-bottom: 15px;
  }

  .page-title {
    font-size: var(--font-size-3xl);
    position: absolute;
    width: 100%;

    .title {
      position: absolute;
      top: -35px;
    }
  }

  .body {
    .avatars {
      text-align: center;
      white-space: normal;

      .tooltip-inner {
        white-space: pre-line;
      }

      .avatar {
        display: inline-block;
        margin-top: 15px;
        margin-right: 5px;

        img {
          max-width: 3rem;
          max-height: 3rem;
          border-radius: 50%;
          object-fit: cover;
        }
      }
    }

    .residences-list {
      .card-wrapper {
        padding-top: 3px;
        padding-left: 3px;

        @media (max-width: 768px) {
          overflow-x: auto;
          width: 100%;
          display: inline-flex;
          margin-bottom: 15px;
          overflow-y: hidden;

          .card {
            cursor: pointer;
            min-width: 150px;
            margin-right: 15px;
          }

          .card-header {
            width: 150px;
          }
        }

        @media (min-width: 769px) {
          overflow-y: auto;
          max-width: 180px;
          height: 65vh;
          margin-left: auto;
          width: 80%;

          .card {
            width: calc(100% - 5px);
          }
        }

        .selected-residence {
          box-shadow: 0 0 6px rgba(35, 173, 255, 1)!important;
          color: rgba(35, 173, 255, 1)!important;
        }

        .card {
          box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
          margin-bottom: 15px;
          transition: all .1s ease-in-out;

          &:hover {
            box-shadow: 0 0 6px rgba(35, 173, 255, 1);
            transform: scale(1.01);
            color: rgba(35, 173, 255, 1)!important;
            cursor: pointer;
          }

          .card-header {
            text-align: center;
            background: rgba(211, 211, 211, 0.32);
            font-size: var(--font-size-5xl);

            .icon {
              padding: 15px;
            }
          }
          /* Add some padding inside the card container */
          .container {
            padding: 2px 16px;
            width: initial;
            text-align: center;
            background: white;
          }
        }
      }
    }

    .residence-details {
      .residence-details-card {
        width: 100%;
        margin: 0 auto;

        .details-panel {
          &::before,&::after {
            display: none;
          }

          @media (max-width: 768px) {
            min-height: 300px;
          }
        }
      }
    }
  }

  .list-header {
    background: #eee;

    h4 {
      margin-bottom: 0;
    }

    button {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .panic-style{
    display: flex;
    align-items: center;
    justify-content: space-between;

  }
}


