label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 0.5rem;
  font-weight: 500;
}

.form-group {
  margin-bottom: 1rem;

  &.has-success .form-control, &.has-success .input-group:not(:focus-within) {
    border-color: var(--green-500);
    box-shadow: none;
  }

  &.has-error .form-control, &.has-error .input-group:not(:focus-within) {
    border-color: var(--red-500);
    box-shadow: none;
  }

  &.has-error .form-control:focus, &.has-success .form-control:focus,
  &.has-error .form-select:focus, &.has-success .form-select:focus {
    border-color: var(--primary-500);
    box-shadow: var(--ring-app-500);
  }

  &.has-error .input-group .form-control:focus,
  &.has-success .input-group .form-control:focus,
  &.has-error .input-group .input-group-addon,
  &.has-success .input-group .input-group-addon {
    box-shadow: none;
    border: none;
  }
}

.form-control {
  box-shadow: none;
  border-color: var(--neutral-200);
  font-size: var(--font-size-base);

  &.form-control-sm {
    font-size: var(--font-size-sm);
  }

  &.form-control-lg {
    font-size: var(--font-size-lg);
  }

  &:focus {
    border-color: var(--primary-500);
    box-shadow: var(--ring-app-500);
  }
}

.input-group {
  --border-radius: 0.375rem;

  border: 1px solid var(--neutral-200);
  border-radius: var(--border-radius);
  transition: all 0.2s;

  &:focus-within, .form-control.has-error:focus, .form-control.has-success:focus {
    border-color: var(--primary-500);
    box-shadow: var(--ring-app-500);
  }

  &.input-group-sm {
    --border-radius: 0.25rem;
  }

  &.input-group-lg {
    --border-radius: 0.5rem;
  }

  .input-group-addon {
    display: flex;
    align-items: center;
    padding: 0.375rem 0.75rem;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    white-space: nowrap;
    border-radius: var(--bs-border-radius);
  }

  .input-group-addon, .input-group-text {
    background-color: white;
    border: none;
    color: var(--neutral-500);
    font-size: var(--font-size-base);

    & + .form-control {
      padding-left: 0;
    }
  }

  .input-group-btn {
    &:first-child  {
      border-top: none;
      border-right: 1px solid var(--neutral-200);
      border-bottom: none;
      border-left: none;
      margin-right: 0 !important;

      & > .btn-default {
        border: none;

        & + .btn-default {
          border-left: 1px solid var(--neutral-200);
        }
      }
    }

    &:last-child {
      border-top: none;
      border-right: none;
      border-bottom: none;
      border-left: 1px solid var(--neutral-200);
      margin-left: 0 !important;

      & > .btn-default {
        border: none;

        & + .btn-default {
          border-left: 1px solid var(--neutral-200);
        }
      }
    }
  }

  &.input-group-sm {
    .input-group-addon, .input-group-text {
      padding: 0.25rem 0.5rem;
      font-size: var(--font-size-sm);
      border-radius: var(--bs-border-radius-sm);
    }
  }

  &.input-group-lg {
    .input-group-addon, .input-group-text {
      padding: 0.5rem 1rem;
      font-size: var(--font-size-2xl);
      border-radius: var(--bs-border-radius-lg);
    }
  }

  & > .btn {
    border-top: 0;
    border-bottom: 0;

    & + .btn, &:last-child {
      border-right: 0;
    }
  }

  .input-group-btn {
    position: relative;
    font-size: 0;
    white-space: nowrap;

    .btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .form-control {
    box-shadow: none;
    border: none;

    &:only-child {
      border-radius: var(--border-radius);
    }
  }
}

fieldset[disabled] .input-group .form-control > .input-group-addon,
.input-group:has(.form-control[disabled]) > .input-group-addon,
.input-group:has(.form-control[readonly]) > .input-group-addon,
fieldset[disabled] .input-group .form-control > .input-group-text,
.input-group:has(.form-control[disabled]) > .input-group-text,
.input-group:has(.form-control[readonly]) > .input-group-text,
.form-group:has(.form-control:disabled) > .input-group-text,
.form-group:has(.form-control:disabled) > .input-group-addon,
.input-group:has(.form-control:disabled) > .input-group-text,
.input-group:has(.form-control:disabled) > .input-group-addon {
  background-color: var(--bs-secondary-bg);
}

.form-group .error {
  color: var(--red-500);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: white;
  -webkit-text-fill-color: #333;
  -webkit-box-shadow: 0 0 0px 1000px white inset;
  transition: background-color 5000s ease-in-out 0s;
}

.form-select {
  font-size: var(--font-size-base);

  &.form-select-sm {
    font-size: var(--font-size-sm);
  }

  &.form-select-lg {
    font-size: var(--font-size-lg);
  }

  &:focus {
    border-color: var(--primary-500);
    box-shadow: var(--ring-app-500);
  }
}

select.form-control {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 1rem 0.75rem;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;

  &.form-control-sm {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
    padding-left: 0.5rem;
  }

  &.form-control-lg {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
  }
}

.form-check-input {
  &:focus {
    border-color: var(--primary);
    box-shadow: 0 0 0 0.25rem var(--primary-100);
  }

  &:checked {
    background-color: var(--primary);
    border-color: var(--primary);
  }
}
