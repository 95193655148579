/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

  /* Hide default HTML checkbox */
  input {
    display: none;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
      position: absolute;
      content: "";
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

    /* Rounded sliders */
    &.round {
      border-radius: 34px;
    }

    &.round:before {
      border-radius: 50%;
    }
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }
}

.switch.switch-sm {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 18px;

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
      position: absolute;
      content: "";
      height: 14px;
      width: 14px;
      left: 2px;
      bottom: 2px;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
    }

    /* Rounded sliders */
    &.round {
      border-radius: 17px;
    }

    &.round:before {
      border-radius: 50%;
    }
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(13px);
    -ms-transform: translateX(13px);
    transform: translateX(13px);
  }
}

.switch.switch-md {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 22px;

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;

    &:before {
      height: 18px;
      width: 18px;
      left: 2px;
      bottom: 2px;
    }

    /* Rounded sliders */
    &.round {
      border-radius: 19px;
    }

  }

  input:checked + .slider:before {
    -webkit-transform: translateX(17px);
    -ms-transform: translateX(17px);
    transform: translateX(17px);
  }
}

.switch input:checked + .slider {
  background-color: var(--primary);

  &.primary{
    background-color: var(--primary);
  }

  &.secondary{
    background-color: var(--green-500);
  }

  &.danger{
    background-color: var(--red-500);
  }

}

.switch input:focus + .slider {
  box-shadow: 0 0 1px var(--primary);

  &.primary{
    box-shadow: 0 0 1px var(--primary);
  }

  &.secondary{
    box-shadow: 0 0 1px var(--green-500);
  }

  &.danger{
    box-shadow: 0 0 1px var(--red-500);
  }
}
