.app-suggestion-panel {
  position: absolute;
  bottom: 0px;
  padding: 20px 5px;
  background-color: rgba(0,0,0,0.9);
  position: fixed;
  width: 100%;
  z-index: 999999;

  .close-button {
    display: inline-block;
    padding: 10px 5px 10px 2px;
    color: #fff;
  }

  .image-wrapper {
    display: inline-block;

    img {
      height: 45px;
    }
  }

  .info {
    display: inline-block;
    color: white;
    padding: 0 10px;
    vertical-align: middle;

    .info-title {
      font-size: var(--font-size-base);
      margin-bottom: .4em;
    }

    .info-text {
      font-size: var(--font-size-sm);
      margin: 0;
    }
  }

  .btn-use {
    float: right;
    background-color: transparent;
    outline: none;
    color: #fff;
    font-size: small;
    height: auto;
    font-weight: normal;
    padding: 5px 10px;
    border-color: white;
    margin-top: 8px;
  }
  .btn-use:hover,
  .btn-use:focus {
    color: #fff;
    background-color: #53A3CD;
    border-color: #53A3CD;
  }
}
