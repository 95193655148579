.close-allowaccess-modal, .close-noise-modal, .close-generic-modal, .close-devilery-modal {

  .files-scroll {
    margin-top: 0px;
  }

  textarea{
    resize: none;
  }

}
