.condo-residences {

  $height: calc(100vh - 180px);
  $table-min-height: 400px;
  $panel-height: calc(100vh - 303px);

  .ngx-datatable.scroll-vertical {
    height: $height;
    min-height: $table-min-height;
  }

  .datatable-pager {
    display: none;
  }

  .datatable-body-cell {
    cursor: pointer;

    .requests-badge {
      background-color: var(--red-500);
      position: absolute;
      top: 0px;
      right: 10px;
    }
  }

  .dropdown {
    li {
      cursor: pointer;
    }
  }

  .list-group-item-text {
    cursor: pointer;
    color: #333;
    font-size: var(--font-size-base);
    &:hover{
      text-decoration: underline;
    }
  }

  .list-header {
    background: var(--neutral-50);

    h4 {
      margin-bottom: 0;
    }

    .btn-link {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  h4 {
    font-size: var(--font-size-lg);
  }

  .bottom-loading {
    .progress-linear {
      position: fixed !important;
      bottom: 35px;
    }
  }

  .vehicle-wrapper {
    padding-right: 4px;
    display: flex;
    align-items: center;

    a, .btn-group, .vehicle-icon {
      flex: 0;
    }

    .vehicle-info {
      flex: 1;
      flex-wrap: wrap;
    }
  }

  .vehicle-image {
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }


  .row.display-flex {
    display: flex;
    flex-wrap: wrap;
  }

  .row.display-flex > [class*='col-'] {
    display: flex;
    flex-direction: column;
  }

}
:host::ng-deep{
.header-list{
  h4{
    font-size: var(--font-size-base);
    margin:0;
    align-self: start;
    color: var(--neutral-600);
  }
  button{
    font-size: var(--font-size-sm);
    padding:3px 10px;
  }
}
.condo-page{
  padding: 10px !important;
  border: 1px solid var(--neutral-200) !important;
}
}
