.create-condo-modal {
  .error-message{
    color: #f82922;
    font-size: smaller;
    margin-bottom: -16px;
  }

  .form-group {
    margin-bottom: 25px;
  }

  .modal-footer {
    padding-top: 15px;
  }

  .user-profile-picture {
    text-align: center;
    margin-bottom: 15px;

    .picture {
      display: block;
      height: 15vh;

      img {
        max-height: 15vh;
      }
    }

    .change-imagem-link {
      cursor: pointer;

      button {
        border-color: transparent;
        color: var(--blue-500);
      }
    }

    .loader-wrapper {
      padding-top: 5vh;

      .mini-loader {
        margin: 0 auto;
        width: 50px;
        height: 50px;
      }
    }
  }
}
