.create-local {
  .timepicker {
    table {
      display: inline;
    }

    .until-label {
      margin-left: 5px;
    }

    .until-label, .from-label {
      line-height: 58px;
      vertical-align: text-top;
      margin-right: 3px;
    }

    .add-period-button {
      vertical-align: text-top;
      margin-top: 13px;
    }
  }

  .period-picker {
    display: inline-block;
  }

  textarea {
    resize: vertical;
  }

  .invalid-border {
    border: 1px solid red;
  }

  .penalties-wrapper {
    border: solid 1px var(--neutral-300);
    border-radius: 4px;
    padding: 6px 12px;
  }

  .periods-wrapper {
    border: solid 1px var(--neutral-300);
    border-radius: 4px;
    padding: 6px 12px;

    &.has-success {
      border-color: #3c763d;
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075)
    }

    &.has-error {
      border-color: #a94442;
      -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
      box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    }
  }

  .remove-period-btn {
    &:hover {
      color: #a94442;
    }
  }

  .switch:before, .switch:after {
    color: #6b7381;
    line-height: 1.25rem;
    width: 3rem;
    text-align: center;
    font-weight: 600;
    font-size: var(--font-size-xxs);
    text-transform: uppercase;
    letter-spacing: 2px;
    position: absolute;
    bottom: 0;
    transition: opacity 0.25s;
  }

  .switch:before {
    content: 'NÃO';
    left: -2.625rem;
    opacity: 1;
    color: #333;
  }

  .switch:after {
    content: 'SIM';
    right: -2.625rem;
    opacity: 0.5;
  }

  .switch.active:before {
    opacity: 0.5;
  }

  .switch.active:after {
    opacity: 1;
    color: #333;
  }

  .switch.disabled, .switch:has(input:disabled) {
    pointer-events: none;
    opacity: 0.5;
  }

  .switch.disabled:before, .switch.disabled:after {
    color: #c0c0c0;
  }

  .switch.disabled .slider {
    background-color: #ddd;
  }

  .local-description{
    resize: none;
    max-height: 250px;
    overflow-y: auto !important;
  }

  .local-rules {
    resize: none;
    max-height: 300px;
    overflow-y: auto !important;
  }

}

.extra-fees {
  border: solid 1px var(--neutral-300);
  border-radius: 4px;
  padding: 6px 12px;

  .error {
    position: absolute;
    top: 100%;
  }
}
