.new-contact-modal {

  .contact-photo-container, .id-photo-container {
    position: relative;

    &.uploadingImage {
      .contact-photo, .id-photo {
        opacity: 0.3;
      }

      .middle {
        opacity: 1;
      }

    }

    &:hover {
      cursor: pointer;
      .contact-photo, .id-photo{
        opacity: 0.3;
      }

      .middle {
        opacity: 1;
      }
    }

    .contact-photo, .id-photo {
      opacity: 1;
      transition: .3s ease;
      backface-visibility: hidden;
      -webkit-border-radius: 4px;
      -moz-border-radius: 4px;
      border-radius: 4px;
    }

    .contact-photo{
      width: 140px;
      height: 140px;
      display: block;
    }

    .id-photo{
      width: auto;
      max-height: 140px;
      max-width: 100%;
    }

    .middle {
      transition: .3s ease;
      opacity: 0;
      position: absolute;
      top: 60%;
      left: 50%;
      transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      white-space: nowrap;

      .text {
        color: white;
        font-size: var(--font-size-base);
        padding: 8px 20px;
        -webkit-border-radius: 4px;
        -moz-border-radius: 4px;
        border-radius: 4px;

        &.from-file{
          background-color: var(--primary);
          margin-top: 10px;
        }

      }

      .loading{
        text-align: center;
        color: var(--primary);
      }
    }

  }

  .id-photo-container{
    .fa-id-card{
      font-size: 8.75rem;
      opacity: 0.5;
    }

    .middle{
      .text{
        white-space: nowrap;
      }
    }
  }

  .vehicle-icon{
    display: inline-block;
    i{
      font-size: var(--font-size-2xl);
    }

  }

  .dropdown-toggling {

    padding: 5px;
    border-radius: 8px;
    padding-left: 10px;

    &:hover {
      cursor: pointer;
      background: rgba(230, 230, 230, 0.4);
    }

    .action-dropdown {
      color: transparent;
    }

    &:hover {
      .action-dropdown {
        color: initial;
      }
    }

    i {
      font-size: var(--font-size-2xl);
      margin-right: 5px;
      margin-bottom: 5px;
    }

    h5 {
      display: inline;
    }

    .dropdown-list{
      cursor: pointer;
    }
  }

  .id-upload-button {
    button {
      border: 2px dashed #dddfe2;
      border-radius: 2px;
      box-sizing: border-box;
      width: 100%;
      height: 140px;
      span {
        white-space: normal;
      }
      i {
        color: #90949C;
      }
    }
  }

  .tooltip-inner{
    max-width: 350px;
  }

}

