/* ----------------------- Global variables ----------------------- */

$colors: (
  primary:    #9500ff,
  secondary:  #32DB64,
  danger:     #f53d3d,
  light:      #f4f4f4,
  dark:       #222,
  favorite:   #69BB7B,
  econdos-dark: #5A5A5A,
  econdos-green: #4CAF50,
  econdos-red: #FF5722,
  econdos-blue: #387ef5,
  econdos-lightgray: #eae8e8,
  econdos-gray: #bdbdbd,
  facebook-gray: #90949c
);
