.house-picker-modal {

  .select-street-label{
    font-size: var(--font-size-2xl);
    padding-top: 5px
  }

  .search-street-input{
    width: 50%;
    max-width: 280px;
    padding: 8px;
  }

  .street-list {
    height: 280px;
    overflow-y: auto;

    .list-group-item {
      &:hover {
        cursor: pointer;
      }
    }
  }

  .number-list{
    max-height: 280px;
    overflow-y: auto;
  }

  .residence-cell {
    display: inline-block;
    padding: 4px;
    //border: solid 1px #555;
    margin: 0 2px;
    width: 50px;
    text-align: center;
    font-size: var(--font-size-base);
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      outline: 2px auto #a5c7fe;
      outline-offset: -2px;
    }
  }

  .checked {
    background-color: var(--primary);
    border-radius: 50%;
    font-weight: bold;
  }

  .disabled {
    opacity: 0.3;
  }

}
