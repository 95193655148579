.fc {
  .fc-calendar {
    background-color: var(--light)
  }

  .fc-button {
    @extend .btn;
  }

  .fc-button:hover,
  .fc-button:focus,
  .fc-button:active,
  .fc-button-primary,
  .fc-button-primary:focus,
  .fc-button-primary:disabled,
  .fc-button-primary:disabled:hover,
  .fc-button-primary:disabled:focus,
  .fc-button-primary:disabled:active,
  .fc-button-primary:active:focus,
  .fc-button-primary:not(:disabled):active,
  .fc-button-primary:not(:disabled):active:focus {
    @extend .btn-default;
    box-shadow: none;
  }

  .fc-button-primary:not(:disabled).fc-button-active,
  .fc-button-primary.fc-button-active:focus:not(:disabled) {
    @extend .btn-app;
    box-shadow: none;
  }

  .fc-event {
    cursor: pointer !important;
  }

  @media (max-width: 720px) {
    .fc-toolbar {
      display: flex !important;
      flex-direction: column !important;
      align-items: center !important;
      gap: 10px;
    }
  }
}
