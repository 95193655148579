.occurrence-creator {

  width: 100%;

  .header {
    border-bottom: 1px solid #dddfe2;
    padding: 5px;
    color: #585c62;
  }

  .footer {
    border-radius: 0 0 3px 3px;
    border-top: 1px solid #dddfe2;
    padding: 5px 10px;
  }

  .content {
    padding: 10px 10px;

    .inputs-wrapper {
      display: inline-block;
      width: calc(100% - 50px);
      margin-left: 4px;

      input, textarea {
        border: none;
        display: block;
        border-bottom: 1px solid #e5e5e5;
        margin-left: 5px;
        color: #1d2129;
        padding-top: 10px;
        &:focus {
          outline: none;
        }

        &.invalid {
          border-color: #a94442;
        }
      }

      .title-info {
        width: 75%;
        margin-top: 4px;
        border-bottom: 1px solid #e5e5e5;
        color: #1d2129;
      }

      .title {
        width: 80%;
        -webkit-transition: width 1s ease;
        -moz-transition: width 1s ease;
        -o-transition: width 1s ease;
        -ms-transition: width 1s ease;
        transition: width 1s ease;

        &:first-letter {
          text-transform: uppercase;
        }

        &:focus {
          width: 100%;
        }
      }

      .description-info {
        width: 80%;
        border-bottom: 1px solid #e5e5e5;
        margin-bottom: 0px;
        margin-top: 5px;
        color: #1d2129;
        white-space: pre-line;
      }

      .description {
        width: 100%;
        resize: none;
        min-height: 100px;

        &:first-letter {
          text-transform: capitalize;
        }
      }

    }

  }

  .error-message {
    color: #a94442;
  }

  .creator-card {
    background: #FFF;
    margin-bottom: 20px;
    border-radius: 5px;
    padding: 5px;

    &.highlight {
      box-shadow: 0 0 0 99999px rgba(0, 0, 0, .8);
      position: relative;
      z-index: 1000;
      pointer-events: auto;
      transition: all 0.5s ease;
    }

    .btn-padding {
      padding: 2px 8px;
    }

    .upload-button {
      display: inline-block;
      padding: 2px 8px;
      margin-bottom: 0;
      font-size: var(--font-size-base);
      font-weight: 400;
      line-height: 1.42857143;
      text-align: center;
      white-space: nowrap;
      vertical-align: middle;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      cursor: pointer;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      background-image: none;
      background-color: transparent;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      box-shadow: none;
      border-color: transparent;
      color: var(--blue-500);
      border-radius: 0;

      &:focus {
        outline: none;
      }

    }
  }

  .residence-span {
    display: inline-block;
    background: #e9ebee;
    border: 1px solid #9cb4d8;
    border-radius: 2px;
    color: #162643;
    cursor: default;
    height: 1.6em;
    margin: 0 4px 2px 0;
    padding: 0 0 0 3px;
    position: relative;
    white-space: nowrap;

    .remove-residence {
      margin-left: 7px;
      margin-right: 3px;
    }
  }

  .residence-select {
    margin-top: 10px;
    select {
      min-width: 100px;
    }
  }

  residences-autocomplete, residents-autocomplete {
    .component-wrapper {
      display: inline-block;

      input {
        border: none;
        display: block;
        border-bottom: 1px solid #e5e5e5;
        margin-left: 5px;
        color: #1d2129;
        padding-top: 10px;
        &:focus {
          outline: none;
        }
      }
    }
  }

}
