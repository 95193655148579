/*How to use
<div class="loader">Loading...</div>
*/

.mini-loader {
  z-index: 1;
  width: 30px;
  height: 30px;
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 3px solid #030303;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /* Add animation to "page content" */
  .animate-bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s
  }

  @-webkit-keyframes animatebottom {
    from {
      bottom: -20px;
      opacity: 0
    }
    to {
      bottom: 0px;
      opacity: 1
    }
  }

  @keyframes animatebottom {
    from {
      bottom: -20px;
      opacity: 0
    }
    to {
      bottom: 0;
      opacity: 1
    }
  }
}

.loader {
  //position: absolute;
  //left: 50%;
  //top: 50%;
  z-index: 1;
  width: 120px;
  height: 120px;
  //margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #030303;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;

  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /* Add animation to "page content" */
  .animate-bottom {
    position: relative;
    -webkit-animation-name: animatebottom;
    -webkit-animation-duration: 1s;
    animation-name: animatebottom;
    animation-duration: 1s
  }

  @-webkit-keyframes animatebottom {
    from {
      bottom: -100px;
      opacity: 0
    }
    to {
      bottom: 0px;
      opacity: 1
    }
  }

  @keyframes animatebottom {
    from {
      bottom: -100px;
      opacity: 0
    }
    to {
      bottom: 0;
      opacity: 1
    }
  }
}

.md-loader {
  height: 4px;
  width: 100%;
  position: relative;
  overflow: hidden;
  background-color: #ddd;


  @keyframes loading {
    from {
      left: -200px;
      width: 30%;
    }
    50% {
      width: 30%;
    }
    70% {
      width: 70%;
    }
    80% {
      left: 50%;
    }
    95% {
      left: 120%;
    }
    to {
      left: 100%;
    }
  }
}

.md-loader:before {
  display: block;
  position: absolute;
  content: "";
  left: -200px;
  width: 200px;
  height: 4px;
  //background-color: #2980b9;
  background-color: var(--primary-600);
  animation: loading 2s linear infinite;
}




