.notification-panel {

  //.slimscroll-wrapper{
  //  width: 100% !important;
  //}

  position: fixed;
  right: 0px;
  top: 51px;
  width: 300px;
  transition: 0.5s;
  z-index: 99;

  //Hided
  &.hided {
    right: -300px;
  }

  .expand-button, .collapse-arrow {
    font-size: var(--font-size-3xl);
    color: #333;
    &:hover {
      color: #888;
    }

    &:focus {
      outline: none;
    }

  }

  .collapse-arrow {
    padding: 0;
    float: right;
    margin-right: 5px;
  }

  .notification-spinner {
    color: var(--primary);
    height: 50px;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .no-notifications-message {
    text-align: center;
    color: gray;
    font-size: var(--font-size-lg);
    padding: 10px;
  }

  .no-more-notifications {
    text-align: center;
    color: gray;
    font-size: var(--font-size-xl);
    padding-top: 15px;
    padding-bottom: 15px;
  }

  .infinite-spinner {
    color: var(--primary);
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .panel-body {
    padding: 0px;
    .slimscroll-wrapper {
      height: auto !important;
    }
  }

  .title-wrapper {
    padding: 15px 0px 5px 10px;
  }

  .notification-list {
    max-height: calc(100vh - 110px);
    height: calc(100vh - 110px);
    overflow-y: auto;
    overflow-x: hidden;
    //Use only with slimscroll
    //padding-right: 10px;
    .notification-card {

      padding: 7px 5px 7px 15px;
      border-bottom: solid 1px #F3F3F3;
      border-top: solid 1px #F3F3F3;
      max-width: 300px;

      &.read {
        background-color: #fff;
      }

      &:not(.read) {
        background-color: #f3f3f3;
      }

      &:hover {
        cursor: pointer;
        border-top: solid 1px;
        border-color: var(--primary);
        //-webkit-transform: scale(1.03);
        //-moz-transform: scale(1.03);
        //-ms-transform: scale(1.03);
        //-o-transform: scale(1.03);
        //transform: scale(1.03);
      }

      .user-picture {
        height: 40px;
        width: 40px;
        border-radius: 50%;
        margin-right: 10px;
      }

      .content-wrapper {
        width: 190px;
        .notification-title {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-weight: bold;

        }
      }

      .read-icon {
        margin-top: 10px;
        color: var(--primary);
        .unread-icon:hover {
          cursor: pointer;
          &:before {
            content: '\f046';
          }
        }
      }
    }
  }

  .title-wrapper {

    .refresh-list, .read-all, .notification-settings {
      padding: 4px 4px;
      font-size: var(--font-size-xl);
      &:focus {
        outline: none;
      }
      i {
        font-size: var(--font-size-xl);
        &:hover {
          transform: scale(1.2);
        }
      }

    }

    .read-all {
      i:hover {
        &:before {
          content: '\f046';
        }
      }
    }

  }

  .notification-badge {
    background: red;
    color: #fff;
    font-size: var(--font-size-sm);
    position: absolute;
    padding: 2px 4px;
    border-radius: 50%;
    top: 13px;
    left: 25px;
    min-width: 22px;
  }

}

@media (max-width: 768px) {
  .notification-panel {

    .notification-badge {
      top: 5px;
      font-size: var(--font-size-xs);
    }

    //Hided
    &.hided {
      width: auto;

      .panel {
        margin: 0;
        background: none;
        border-radius: 0;
        border: none;
        .panel-body {
          padding: 0;
        }
      }

    }
  }
}
